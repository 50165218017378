
.section__upper-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-bottom: 50px;
  }
  
  .section__upper-list.block-white {
    color: #161A20;
  }
  
  .section__upper-logo {
    display: block;
    width: 130px;
    height: 74px;
  }

  .section__upper-item {
    transition: color 0.4s ease-in-out;
  }

  .section__upper-item:nth-child(n+3):hover {
    cursor: pointer;
    color: #FFAC30;
  }