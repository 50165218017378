.sidebar {
    position: absolute;
    top: 15%;
    left: 3%;
  }
  
  .sidebar__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 70vh;
  }
  .sidebar__item {
    display: flex;
  justify-content: center;
  align-items: center;
  }
  
  
  .sidebar__item-title {
    color: rgba(255, 255, 255, 0.50);
    font-size: 1rem;
    font-weight: 500;
    line-height: 100.187%;
    letter-spacing: 4.275px;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    transform: rotate(180deg); 
  
  }

  .sidebar__item-icon {
    fill: rgba(255, 255, 255, 0.5);
    width: 28px;
    height: 24px;
  }

  .sidebar__item-icon {
    transition: fill .2s ease-in-out;
  }

  .sidebar__item-icon:hover {
    fill: white;
  }