.contacts .header {
    background-color: #FBFBFB;
}
.contacts {
    background-color: #FBFBFB;
}

.contacts__title {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 5px;
}

.contact__discr {
    color: #717171;
text-align: center;
font-size: 1.2em;
font-weight: 500;
margin-bottom: 7em;
}
.contact__container {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 12px;
    margin-bottom: 5em;
}
.contacts__block-contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    padding: 40px;
    background: linear-gradient(250deg, #333035 -0.02%, #27262D 33.19%, #201F29 99.58%);
    backdrop-filter: blur(8.164557456970215px);
}
.block__contact-title {
    font-size: 1.6em;
    font-weight: 600;
    margin-bottom: 10px;
}
.block__contact-discr {
    color: #C9C9C9;
font-size: 1.1em;
margin-bottom: 5em;
}



.block__contact-list li {
    margin-bottom: 3em;
    display: flex;
    align-items: center; 
}

.block__contact-link {
    display: flex;
    align-items: center; 
}

.block__contact-svg {
    margin-right: 10px; 
}

.block__contact-svg:hover {
    fill: #fa9c10;
}

.block__contact-adress {
    display: flex;
    align-items: center; 
}

.block__contact-social {
    display: flex;
}
.block__lower-item {
    padding: 0 1em 0 0;
}

.contacts__form {
    position: relative;
    width: 60vh;
    margin: auto;
    margin: 4em;
}

.contact__form-block {
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.contact__form-label {
    display: block;
    font-weight: bold;
    margin-bottom: 2em;
    color: #8D8D8D;

}

.contacts
input[type="text"]
{
    margin-right: 1em;
    border: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 2.5em;
    width: 90%;
    outline: none;
}

.contacts
input[type="tel"],
input[type="email"],
textarea {
    border: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 2.5em;
    width: 100%;
    outline: none;
}

.contact__form-list {
    margin-bottom: 4em;
    color: #8D8D8D;
}

.contact__form-checkbox {
    display: flex;
    align-items: center;
    
}


.contacts
input[type="radio"] {
    display: none;
}

.contact__form-checkbox::before {
    content: "";
    border-radius: 10%;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    margin-right: 1em;
}

input[type="radio"]:checked + .contact__form-checkbox::before {
    background: #fa9c10;
}

.contact__form-button {
    position: absolute;
    right: 0;
    padding: 15px 48px;
    font-size: 1.1em;
    background-color: rgba(33, 32, 42, 1);
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.contact__form-button:hover {
    background: #fa9c10;
}

.contacts__block-map {
}