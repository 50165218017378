@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Raleway:300);
:root {
  --font-family: "Roboto Condensed", sans-serif;
  --content-width: 1920px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #1E1E1E;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable value-keyword-case */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
  color: inherit;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.header__upper.wrapper {
  padding: 0 100px 20px;
}

.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 20px 0 24px 0;
  background: rgba(34, 31, 31, 0.5);
  z-index: 100;
}

.header_fixed {
  background-color: #15191f;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.header__upper-list {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.header__upper-item:last-child {
  display: flex;
  align-items: center;
}

.header__upper-logo {
  width: 130px;
  height: 74px;
}

.header__upper-button {
  display: flex;
  border: none;
  background-color: inherit;
  color: #FFAC30;
  align-content: center;
  align-items: center;
}

.header__upper-button:hover {
  border: none;
  background-color: inherit;
  opacity: 0.8;
  color: #FFAC30;
}

.header__lower {
  background: rgba(21, 25, 31, 0.89);
  color: white;
  width: 100%;
  z-index: 100;
  transition: background-color 0.3s; /* Плавное изменение фона */
  background-color: rgba(21, 25, 31, 0.89); /* Начальный цвет фона */
}

.header__lower-list {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
}

.header__lower-list.wrapper {
  padding-top: 0;
  padding-bottom: 5px;
}

.header__lower-item {
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.header__upper-item span {
  font-size: 0.9em;
  color: #9AC2DB;
}

.header__lower-link {
  font-size: 0.8vw;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  padding: 15px 20px;
  position: relative;
  margin-bottom: 7px;
}

.header__lower-link:hover {
  color: #fff;
}

.header__lower-link:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.header__lower-link:hover:after {
  width: 100%;
  left: 0;
}

/** Footer **/
.footer {
  padding: 75px 0 10px;
  position: relative;
  background-color: #23222C;
  color: #FFF;
  background-image: url(../img/slogan.svg), url(../img/eclipse.svg), url(../img/Exmlid.svg);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: 80% 0, 100% 0, 0% 0%;
}

.footer__logo-text {
  font-size: 0.5em;
  margin-bottom: 96px;
}

.footer__main {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-bottom: 50px;
}

.footer__list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 321px;
}

.footer__item {
  font-size: 1em;
  margin-bottom: 15px;
}

.footer__contact-link {
  display: flex;
  align-items: center;
}

.footer__contact-item {
  margin-bottom: 20px;
}

.footer__contact-svg {
  margin-right: 15px;
}

.footer__social-list {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  padding: 25px 0;
  margin: 0 auto;
  height: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 35px;
}

.footer__social-icon {
  fill: rgba(255, 255, 255, 0.75);
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.footer__lower {
  display: flex;
  flex-direction: column;
  background: rgba(22, 27, 34, 0.5);
  width: 100%;
  align-items: center;
  font-size: 0.8em;
  line-height: 20px;
  padding-top: 25px;
  padding-bottom: 45px;
}

.footer__lower-design {
  margin-bottom: 12px;
}

.footer__lower-adress {
  margin-bottom: 12px;
}

.footer__lower-inn {
  margin-bottom: 38px;
}

.footer__lower-block {
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.footer__lower-text {
  width: 47%;
}

.footer__basement-list {
  display: flex;
  justify-content: space-between;
  background-color: rgba(10, 20, 47, 0.1);
  padding-top: 35px;
  padding-bottom: 85px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 25px;
}

.footer__basement-item {
  padding: 35px 0;
}

.footer__text {
  text-align: center;
  font-size: 0.7rem;
  padding: 30px 0;
}

.sidebar {
  position: absolute;
  top: 15%;
  left: 3%;
}

.sidebar__list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 70vh;
}

.sidebar__item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar__item-title {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
  font-weight: 500;
  line-height: 100.187%;
  letter-spacing: 4.275px;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.sidebar__item-icon {
  fill: rgba(255, 255, 255, 0.5);
  width: 28px;
  height: 24px;
}

.sidebar__item-icon {
  transition: fill 0.2s ease-in-out;
}

.sidebar__item-icon:hover {
  fill: white;
}

.section__upper-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin-bottom: 50px;
}

.section__upper-list.block-white {
  color: #161A20;
}

.section__upper-logo {
  display: block;
  width: 130px;
  height: 74px;
}

.section__upper-item {
  transition: color 0.4s ease-in-out;
}

.section__upper-item:nth-child(n+3):hover {
  cursor: pointer;
  color: #FFAC30;
}

/** Articles **/
.article a:hover {
  color: inherit;
}

.section__upper-list.block-white.article {
  color: #161A20;
  margin-bottom: 10px;
}

.article {
  background-color: #FFF;
}

.article .header {
  padding: 10px 0 50px;
}

.article .wrapper {
  padding: 0 175px;
}

.article .section__upper-list {
  margin-bottom: 5px;
}

.breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 30px;
  color: #152824;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: -0.2px;
  opacity: 0.9;
  text-align: left;
}

.breadcrumb__item + .breadcrumb__item::before {
  display: inline-block;
  padding-left: 0.3em;
  padding-right: 0.3em;
  color: #6c757d;
  content: "/";
}

.article__main {
  font-family: "Roboto", sans-serif;
  margin-right: 80px;
  padding-bottom: 5em;
}

.article__img {
  width: 100%;
  margin-bottom: 27px;
  height: 400px;
  border-radius: 15px;
}

.article__data {
  display: inline-block;
  color: #152739;
  font-size: 1.1em;
  text-transform: uppercase;
  opacity: 0.4;
  margin-bottom: 24px;
}

.article__main h1 {
  color: #152739;
  font-size: 2em;
  line-height: 1em;
  letter-spacing: -1px;
  margin-bottom: 1em;
}

.article__main h2 {
  font-size: 1.6em;
  margin-bottom: 1.5em;
  letter-spacing: -1px;
}

.article__main h3 {
  color: #152739;
  font-size: 1.3em;
  letter-spacing: -0.5px;
  margin-bottom: 0.8em;
}

.article__main h4 {
  color: #152739;
  font-size: 1.2em;
  letter-spacing: -0.5px;
  margin-bottom: 0.8em;
}

.article__main p {
  text-align: justify;
  color: #000;
  font-size: 1.3em;
  line-height: 1.4em;
  opacity: 0.8;
  margin-bottom: 1.1em;
}

.article__main ul {
  margin-bottom: 1.8em;
  list-style-type: disc;
  list-style-position: inside;
}

.article__main li {
  margin-bottom: 0.7em;
  font-size: 1.1em;
  list-style-position: inside;
}

.article__img.right {
  padding-left: 41px;
}

.article__text p {
  margin-bottom: 1em;
  line-height: 120%;
}

.article__text p > span {
  font-size: 1.2em;
  font-weight: 700;
  font-style: italic;
}

.article__aside {
  max-width: 350px;
  margin-top: 5em;
}

.article__aside-news {
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 2em;
}

.article__aside-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
}

.article__aside-item {
  padding-bottom: 20px;
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(47, 34, 34, 0.2);
}

.article__aside-title {
  color: #2F2222;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

.article__aside-data {
  color: rgba(47, 34, 34, 0.4);
  font-size: 1.1em;
}

.article__aside-form {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: rgba(93, 113, 221, 0.05);
  padding: 41px 30px 50px;
}

.article__aside-label {
  color: #2F2222;
  text-align: center;
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 32px;
}

.article__aside-input {
  padding: 13px 32px;
  border-radius: 20px;
  background: #FFF;
  border: none;
  margin-bottom: 21px;
}

.article__aside-button {
  border-radius: 20px;
  border: none;
  background: #5D71DD;
  color: #FFF;
  font-size: 1.1rem;
  padding: 13px 68px;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

.articles__link {
  display: flex;
  align-items: center;
  margin: 30px 15px 30px 0;
}

.articles__link-svg {
  width: 75px;
  height: 75px;
  padding-right: 15px;
}

.blog__subscrible-subtitle p {
  text-align: center;
}

/** Articles List **/
.article__title {
  margin-bottom: 50px;
}

.article__list {
  background-color: #FFF;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 5em;
}

.article__item-article {
  width: 45%;
}

.article__item:hover .article__item-picture {
  filter: brightness(1);
  transform: scale(1.01);
}

.article__item-article {
  position: relative;
}

.article__item-picture {
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.article__item-img {
  display: block;
  filter: brightness(0.6);
  width: 100%;
  height: 25vh;
  -o-object-fit: cover;
  object-fit: cover;
  transition: filter 1s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

.hashtag {
  position: absolute;
  top: -1px;
  right: 5px;
  padding-top: 6px;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 0.8em;
}

.hashtag-block {
  position: absolute;
  right: 0;
  padding: 4px 14px;
  display: inline-block;
  background-color: #333;
  color: #fff;
  text-align: right;
}

.article__item-title {
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1.1em;
  margin: 8px 0;
}

.article__item-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.7em;
  margin-bottom: 8px;
}

.article__item-link {
  display: inline-block;
  width: 100%;
  color: inherit;
}

.article__item-block {
  display: flex;
  align-items: center;
}

.article__item-icon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  display: inline-block;
}

.article__item-date {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.3em;
  color: #999;
}

.article__item-container {
  position: absolute;
  padding: 30px 90px 30px 30px;
  bottom: 0;
}

.article__item-big .article__item-img {
  display: block;
  height: 60vh;
}

.article__item-big .article__item-title {
  margin-bottom: 9px;
  font-size: 1.4em;
  line-height: 1.5em;
  color: #fff;
}

.article__item-big .article__item-subtitle {
  margin-bottom: 19px;
  font-size: 0.8em;
  line-height: 1.4em;
  color: #fff;
}

.article__item-big .article__item-block {
  margin-bottom: 13px;
}

.article__item-big .article__item-date {
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.4em;
  color: #fff;
}

.article__item-btn {
  padding: 12px 21px;
  display: inline-block;
  font-weight: 700;
  font-size: 0.8em;
  line-height: 1em;
  transition-property: color, background-color, border-color;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
  border: 2px solid #ff6e30;
  color: #fff;
}

.calculator {
  color: #FFF;
  background: linear-gradient(161deg, #3E3D43 31.23%, #12101D 99.81%);
  backdrop-filter: blur(5px);
  padding-top: 25px;
  padding-bottom: 25px;
}

.calculator__title {
  font-size: 2.8em;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1em;
}

.calculator__title-color {
  color: #FFAC30;
}

.price-info {
  text-align: justify;
  font-size: 1.4em;
  line-height: 156.5%;
  margin-bottom: 2em;
  font-weight: 300;
}

.price__discr {
  font-size: 1.1em;
  line-height: 156.5%;
  margin-bottom: 75px;
}

.price__item {
  margin-bottom: 5px;
}

.price__item-info {
  visibility: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  margin-bottom: 50px;
}

.price__item-info.ac-active {
  visibility: visible;
  max-height: 800px;
}

.price__item-title {
  margin-bottom: 30px;
}

.price__item-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
  padding: 40px 20px;
  border: none;
  border-radius: 8px;
  background: rgba(22, 25, 31, 0.6);
  font-size: 1em;
}

.price__item-button:hover {
  outline: 2px solid white;
}

.price__item-discr {
  min-height: 248px;
  font-size: 1.2em;
  line-height: 156.5%;
  padding: 54px 20px;
  border-radius: 8px;
  background: rgba(24, 29, 36, 0.7);
  margin-bottom: 20px;
}

.calculator__subtitle {
  font-size: 2.8em;
  margin-bottom: 1em;
}

.calculator__discr {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.container__position {
  align-self: flex-end;
  padding-bottom: 30px;
}

.calculator__main-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
}

.container__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1em;
}

.box-minmax {
  margin-top: 30px;
  width: 75%;
  display: flex;
  justify-content: space-between;
  font-size: 1.8em;
  color: #FFFFFF;
}
.box-minmax span:first-child {
  margin-left: 10px;
}

.rs-range {
  margin-top: 29px;
  width: 600px;
  -webkit-appearance: none;
}
.rs-range:focus {
  outline: none;
}
.rs-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: none;
  background: #ffffff;
  border-radius: 0px;
  border: 0px solid #010101;
}
.rs-range::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: none;
  background: #ffffff;
  border-radius: 0px;
  border: 0px solid #010101;
}
.rs-range::-webkit-slider-thumb {
  box-shadow: none;
  border: 0px solid #ffffff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  height: 42px;
  width: 22px;
  border-radius: 22px;
  background: rgb(255, 255, 255);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -20px;
}
.rs-range::-moz-range-thumb {
  box-shadow: none;
  border: 0px solid #ffffff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  height: 42px;
  width: 22px;
  border-radius: 22px;
  background: rgb(255, 255, 255);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -20px;
}
.rs-range::-moz-focus-outer {
  border: 0;
}

.rs-label {
  position: relative;
  transform-origin: center center;
  display: block;
  width: 100px;
  height: 80px;
  background: transparent;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  padding-top: 22px;
  box-sizing: border-box;
  border: 2px solid #FFAC30;
  margin-top: 20px;
  margin-left: -50px;
  left: attr(value);
  color: #fff;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 1.4em;
}
.rs-label::after {
  content: "позиций";
  display: block;
  font-size: 1em;
  letter-spacing: 0.07em;
  margin-top: -2px;
}

.calculator-number {
  font-size: 2.5em;
  font-weight: 900;
  color: rgb(255, 174, 53);
  padding-right: 15px;
}

.calculator__position {
  font-size: 1.5em;
  margin-right: 1em;
}

.calculator__position-input {
  max-width: 287px;
  max-height: 60px;
  font-size: 1.8em;
  text-align: center;
  padding: 5px;
  max-width: 287px;
  max-height: 60px;
  font-size: 1.8em;
  text-align: center;
  margin-bottom: 1em;
  background-color: inherit;
  color: white;
  border-color: #FFF;
}

.calculator__data-title {
  font-size: 1.6em;
  margin-bottom: 0.8em;
}

.calculator__label {
  display: flex;
  align-items: center;
  max-width: 450px;
  font-size: 1.3em;
  font-style: normal;
  font-weight: 500;
}

.calculator__work-title {
  font-size: 1.6em;
  margin-bottom: 0.8em;
}

.calculator__expertise-title {
  font-size: 1.6em;
  margin-bottom: 0.8em;
}

.calculator__katz-title {
  font-size: 1.6em;
  margin-bottom: 0.8em;
}

.calculator__button {
  border: none;
  border-radius: 14px;
  background: #FFAC30;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 270px;
  height: 60px;
  color: #000;
  text-align: center;
  font-size: 1.25em;
  transition: all 0.3s ease-in-out;
  margin-bottom: 2.5em;
}

.calculator__button:hover {
  background: #fa9c10;
  color: #FFF;
  border: 2px solid #FFF;
}

.calculator__button:focus {
  background: #fa9c10;
  color: #FFF;
  border: 2px solid #FFF;
}

.calculator__button:active {
  background: #fa9c10;
  color: #FFF;
  border: 2px solid #FFF;
}

.calculator__result {
  font-size: 1.8em;
  padding-bottom: 5em;
}

.calculator__group {
  padding: 20px 60px;
  border: 1px solid white;
  display: block;
  margin-bottom: 4em;
}

.calculator input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.calculator__label {
  position: relative;
  margin-right: 1em;
  padding-left: 2em;
  padding-right: 1em;
  line-height: 2;
  cursor: pointer;
}
.calculator__label:before {
  box-sizing: border-box;
  content: " ";
  position: absolute;
  top: 0.3em;
  left: 0;
  display: block;
  width: 1.4em;
  height: 1.4em;
  border: 2px solid white;
  border-radius: 0.25em;
  z-index: -1;
}

/* Checked */
.calculator input[type=radio]:checked + label {
  padding-left: 1em;
  color: black;
}
.calculator input[type=radio]:checked + label:before {
  top: 0;
  width: 100%;
  height: 2em;
  background: white;
}

/* Transition */
.calculator label,
label::before {
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}

.price__result {
  text-align: justify;
  font-size: 1.4em;
  line-height: 156.5%;
  margin-bottom: 75px;
  font-weight: 300;
  text-align: justify;
}

/* style.css */
#quizContainer {
  display: none;
}

fieldset {
  margin-bottom: 20px;
}

#result {
  margin-top: 20px;
}

.question {
  display: none;
}

.question.active {
  display: block;
}

#quizContainer {
  display: none; /* Изначально скрываем квиз */
  position: fixed; /* Фиксированное позиционирование относительно окна браузера */
  top: 0;
  left: 0;
  width: 100vw; /* Ширина во всю ширину вьюпорта */
  height: 100vh; /* Высота во всю высоту вьюпорта */
  background-color: rgba(0, 0, 0, 0.9); /* Полупрозрачный фон */
  z-index: 9999; /* Убедитесь, что z-index выше, чем у других элементов */
  justify-content: center; /* Центрирование содержимого по горизонтали */
  align-items: center; /* Центрирование содержимого по вертикали */
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.quiz {
  margin: 10vh 15vh;
  height: 80vh;
  background-color: rgb(49, 53, 55);
  color: white;
  color: white;
  overflow-y: auto; /* Добавляем прокрутку, если содержимое не помещается */
}

.quiz__close-button {
  position: absolute;
  top: 6vh; /* Расстояние от верхнего края контейнера квиза */
  right: 10vh; /* Расстояние от правого края контейнера квиза */
  background-color: transparent; /* Прозрачный фон */
  color: rgba(255, 255, 255, 0.5);
  border: none; /* Убрать рамку */
  font-size: 44px; /* Размер иконки закрытия */
  cursor: pointer; /* Курсор в виде указателя */
  transition: all ease-in-out 0.3s;
}

.quiz__close-button:hover {
  color: #f09e22; /* Цвет при наведении (красный) */
}

.welcome-block {
  display: flex;
  overflow: hidden;
  height: 100%;
  background-image: url(../img/welcome.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.welcome__main {
  height: 100%;
  padding-top: 60px;
  padding-left: 70px;
  margin-left: 40vw;
  padding-right: 80px;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.welcome__main-logo {
  width: 160px;
  height: auto;
  margin-bottom: 50px;
}

.welcome__main-title {
  font-weight: 300;
  font-size: 36px;
  line-height: 110%;
  padding: 0;
  margin: 0;
}

.welcome__main-discr {
  font-size: 20px;
  line-height: 110%;
  text-wrap: wrap;
  margin-bottom: 35px;
}

.welcome__main-button {
  width: 245px;
  height: 60px;
  background-color: #FFAC30;
  box-shadow: 0 4px 13.7px rgba(255, 187, 12, 0.25);
  border-radius: 35px;
  border: none;
  color: white;
  font-size: 16px;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;
}

.welcome__main-button::before {
  content: "";
  position: absolute;
  top: -100%; /* Увеличиваем начальную точку для лучшего эффекта */
  left: -100%;
  width: 200%;
  height: 200%;
  background: linear-gradient(40deg, transparent 45%, rgba(255, 255, 255, 0.4) 50%, transparent 45%);
  animation: question 2s infinite linear;
}

@keyframes question {
  100% {
    transform: translateX(200%); /* Перемещаем градиент под углом 45 градусов */
  }
}
.welcome__main-button:hover {
  background-color: #f09e22;
}

.welcome__main-contact {
  display: flex;
  flex-direction: column;
}

.welcome__main-tel {
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  margin-bottom: 5px;
  transition: all ease-in-out 0.3s;
}

.welcome__main-tel:hover {
  color: white;
}

fieldset {
  margin-bottom: 20px;
}

#result {
  margin-top: 20px;
}

.question {
  position: relative;
  display: none;
  padding: 5vh 5vh;
  transition: all ease-in-out 0.3s;
}

.question__container {
  display: flex;
  gap: 60px;
  margin-bottom: 15px;
  padding-bottom: 30px;
}

.question__block {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  height: 400px;
}

.question.active {
  display: block;
}

.question__input {
  width: auto;
  font-size: 18px;
  font-weight: 200;
  padding: 15px 460px 15px 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 25px;
  color: rgba(255, 255, 255, 0.8);
  transition: all ease-in-out 0.3s;
  text-wrap: nowrap;
  cursor: pointer;
}

.question__input:hover {
  background-color: rgb(45, 50, 52);
  color: white;
}

/* Стили для кастомного чекбокса */
.question__input input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: 10px;
  width: 15px; /* Начальный размер */
  height: 15px; /* Начальный размер */
  border: 0.5px solid rgba(255, 255, 255, 0.13); /* Граница неактивного чекбокса */
  border-radius: 50%; /* Делаем круглым */
  outline: none; /* Убираем контур на фокусе */
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.13);
  transition: all ease-in-out 0.3s;
}

/* Стили для активного состояния чекбокса */
.question__input input[type=radio]:checked {
  background-color: #f09e22; /* Меняем цвет на жёлтый */
  box-shadow: 0 4px 13.7px rgba(255, 187, 12, 0.25);
}

.question__title {
  font-size: 24px;
  margin-bottom: 35px;
}

.question__container-lower {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 50px 5vw 40px 5vw;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.question__button-block {
  display: flex;
  gap: 30px;
}

.question__button {
  position: relative;
  border-radius: 35px;
  color: white;
  background-color: #FFAC30;
  transition: all ease-in-out 0.3s;
  box-shadow: 0 4px 13.7px rgba(255, 187, 12, 0.25);
  padding: 10px 60px;
  border: none;
  cursor: pointer;
  overflow: hidden;
}

.question__button::before {
  content: "";
  position: absolute;
  top: -100%; /* Увеличиваем начальную точку для лучшего эффекта */
  left: -100%;
  width: 200%;
  height: 200%;
  background: linear-gradient(40deg, transparent 45%, rgba(255, 255, 255, 0.4) 50%, transparent 45%);
  animation: question 2s infinite linear;
}

.question__button:hover {
  background-color: #f09e22;
}

.question__button-back {
  width: 50px; /* Установите желаемый размер */
  height: 50px; /* Установите желаемый размер, равный ширине для создания круга */
  border-radius: 50%; /* Делает кнопку круглой */
  border: 1px solid rgba(255, 255, 255, 0.5); /* Убирает стандартную рамку кнопки */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  padding: 0; /* Убрать стандартный padding кнопки, если он есть */
  cursor: pointer; /* Изменяет курсор на указатель */
  outline: none; /* Убирает стандартный контур при фокусе для некоторых браузеров */
  transition: all ease-in-out 0.3s;
}

.question__button-back:hover {
  fill: white;
  box-shadow: 0 1px 6.7px rgba(255, 255, 255, 0.25);
}

.question__button-back svg {
  fill: white; /* Цвет иконки */
}

.progress-container {
  width: 50%;
}

.progress-label {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.progress-bar-background {
  width: 100%;
  background-color: #eee;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 3px 6.7px rgba(255, 199, 0, 0.25);
}

.progress-bar {
  height: 5px;
  background: linear-gradient(90deg, #f09e22 0%, #e7bb1e 100%);
  border-radius: 8px;
  transition: width 0.3s ease-in-out;
  box-shadow: 0 7px 6.7px rgba(255, 199, 0, 0.25);
}

.questions__contact-container {
  max-width: 50vw;
  margin: 0 auto;
  text-align: center;
}

.questions__contact-form {
  padding: 40px 120px;
  background-color: #26292B;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.questions__contact-title {
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 45px;
}

.questions__contact-discr {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 40px;
}

.questions__contact-form input {
  color: white;
  padding: 0 25px;
  font-weight: 300;
  outline: none;
  height: 45px;
  margin-bottom: 40px;
  background-color: inherit;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all ease-in-out 0.4s;
}

.questions__contact-form input:hover {
  background-color: rgb(45, 50, 52);
  border: 1px solid rgb(255, 255, 255);
}

.questions__contact-tel {
  margin-bottom: 80px;
}

.questions__contact-button {
  height: 60px;
  background-color: #FFAC30;
  box-shadow: 0 4px 13.7px rgba(255, 187, 12, 0.25);
  border-radius: 35px;
  border: none;
  color: white;
  font-size: 16px;
  margin-bottom: 60px;
  position: relative;
  overflow: hidden;
}

.questions__contact-button::before {
  content: "";
  position: absolute;
  top: -100%; /* Увеличиваем начальную точку для лучшего эффекта */
  left: -100%;
  width: 200%;
  height: 200%;
  background: linear-gradient(40deg, transparent 45%, rgba(255, 255, 255, 0.4) 50%, transparent 45%);
  animation: question 4.5s infinite linear;
}

.questions__contact-button:hover {
  background-color: #f09e22;
}

.questions__contact-social {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  font-size: 14px;
}

.questions__contact-wa {
  color: inherit;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
}

.questions__contact-tg {
  color: inherit;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
}

.questions__contact-tg:hover {
  color: #f9d298;
}

.questions__contact-wa:hover {
  color: #f9d298;
}

/* Медиа-запросы для адаптации под мобильные устройства */
@media (max-width: 768px) {
  #quizContainer {
    padding-top: 20px; /* Добавляем отступ сверху */
  }
  .quiz {
    margin: 5vh 5vw; /* Уменьшаем маржин */
    height: auto; /* Делаем высоту автоматической */
    max-height: 80vh; /* Ограничиваем максимальную высоту */
    overflow-y: auto; /* Поддерживаем прокрутку, если содержимое не помещается */
  }
  .quiz__close-button {
    top: 4vh; /* Уменьшаем отступ сверху */
    right: 4vh; /* Уменьшаем отступ справа */
    font-size: 36px; /* Уменьшаем размер иконки закрытия */
  }
  .welcome__main {
    margin-left: 10vw; /* Уменьшаем левый маржин */
    padding-right: 30px; /* Уменьшаем правый паддинг */
    max-width: 80%; /* Увеличиваем максимальную ширину для более лучшего использования пространства */
  }
  .welcome__main-title {
    font-size: 24px; /* Уменьшаем размер шрифта заголовка */
  }
  .welcome__main-discr {
    font-size: 16px; /* Уменьшаем размер шрифта описания */
  }
  .welcome__main-button {
    width: 200px; /* Уменьшаем ширину кнопки */
    height: 50px; /* Уменьшаем высоту кнопки */
  }
  .question__input {
    padding: 15px 15px 15px 10px; /* Уменьшаем паддинг справа */
  }
  .question__button {
    padding: 10px 30px; /* Уменьшаем паддинг кнопки */
  }
  .question__button-back {
    width: 40px; /* Уменьшаем размер кнопки назад */
    height: 40px; /* Уменьшаем высоту кнопки назад */
  }
  .question__container-lower {
    padding: 30px 5vw; /* Уменьшаем паддинг снизу */
  }
  .questions__contact-form {
    padding: 40px; /* Уменьшаем паддинг формы */
  }
}
@media (max-width: 640px) {
  #quizContainer {
    padding-top: 10px; /* Еще меньше отступа сверху */
  }
  .quiz {
    margin: 2vh 2vw; /* Сокращаем отступы вокруг квиза */
    height: 95vh; /* Уменьшаем максимально возможную высоту */
  }
  .question__container {
    display: flex;
    gap: 60px;
    margin-bottom: 15px;
    padding-bottom: 30px;
    flex-direction: column;
    height: auto;
  }
  .question__block {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    height: auto;
  }
  .quiz__close-button {
    top: 2vh; /* Еще меньше отступа сверху */
    right: 2vh; /* Еще меньше отступа справа */
    font-size: 30px; /* Дальнейшее уменьшение размера иконки закрытия */
  }
  .welcome__main {
    margin-left: 5vw; /* Уменьшаем левый маржин */
    margin-right: 5wv;
    padding-right: 5vw; /* Уменьшаем правый паддинг */
    padding-left: 5vw;
    width: 95vw; /* Увеличиваем максимальную ширину для лучшего использования пространства */
    border-left: none;
  }
  .question__input {
    font-size: 14px;
  }
  .welcome__main-logo {
    width: 120px; /* Уменьшаем размер лого */
  }
  .welcome__main-title {
    font-size: 20px; /* Уменьшаем размер шрифта заголовка */
  }
  .welcome__main-discr {
    font-size: 14px; /* Уменьшаем размер шрифта описания */
  }
  .welcome__main-button {
    width: 180px; /* Уменьшаем ширину кнопки */
    height: 45px; /* Уменьшаем высоту кнопки */
    font-size: 14px; /* Уменьшаем размер шрифта кнопки */
  }
  .question__input {
    padding: 12px 12px 12px 10px; /* Уменьшаем паддинги */
  }
  .question__button {
    padding: 8px 20px; /* Уменьшаем размер кнопок */
    font-size: 14px; /* Уменьшаем размер текста в кнопках */
  }
  .question__button-back {
    width: 35px; /* Уменьшаем размер кнопки назад */
    height: 35px; /* Уменьшаем высоту кнопки назад */
  }
  .question__container-lower {
    padding: 20px 3vw; /* Уменьшаем паддинг снизу и по бокам */
  }
  .questions__contact-form {
    padding: 20px; /* Уменьшаем паддинг формы */
  }
  .questions__contact-button {
    height: 50px; /* Уменьшаем высоту кнопки */
    font-size: 14px; /* Уменьшаем размер шрифта кнопки */
  }
  .progress-container {
    width: 30%;
  }
  .questions__contact-container {
    max-width: 80vw;
  }
}
#emailModal {
  display: none;
  position: fixed; /* Фиксированное позиционирование относительно окна браузера */
  top: 0;
  left: 0;
  width: 100vw; /* Ширина во всю ширину вьюпорта */
  height: 100vh; /* Высота во всю высоту вьюпорта */
  background-color: rgba(0, 0, 0, 0.75); /* Полупрозрачный фон */
  z-index: 9999; /* Убедитесь, что z-index выше, чем у других элементов */
  justify-content: center; /* Центрирование содержимого по горизонтали */
  align-items: center; /* Центрирование содержимого по вертикали */
  transition: opacity 0.4s ease-in-out;
  color: white;
}

.email__modal_close-button {
  position: absolute;
  top: 10vh; /* Расстояние от верхнего края контейнера квиза */
  right: 35vh; /* Расстояние от правого края контейнера квиза */
  background-color: transparent; /* Прозрачный фон */
  color: rgba(255, 255, 255, 0.5);
  border: none; /* Убрать рамку */
  font-size: 44px; /* Размер иконки закрытия */
  cursor: pointer; /* Курсор в виде указателя */
  transition: all ease-in-out 0.3s;
}

.email__modal_close-button:hover {
  color: #f09e22; /* Цвет при наведении (красный) */
}

.email__modal-container {
  padding-top: 15vh;
  max-width: 50vw;
  margin: 0 auto;
  text-align: center;
}

.email__modal-form {
  padding: 120px 120px 60px 120px;
  background-color: #26292B;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.email__modal-title {
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 45px;
}

.email__modal-discr {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 40px;
}

.email__modal-form input {
  color: white;
  padding: 0 25px;
  font-weight: 300;
  outline: none;
  height: 50px;
  margin-bottom: 40px;
  background-color: inherit;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all ease-in-out 0.4s;
}

.email__modal-form input:hover {
  background-color: rgb(45, 50, 52);
  border: 1px solid rgb(255, 255, 255);
}

.email__modal-tel {
  margin-bottom: 80px;
}

.email__modal-button {
  height: 60px;
  background-color: #FFAC30;
  box-shadow: 0 4px 13.7px rgba(255, 187, 12, 0.25);
  border-radius: 35px;
  border: none;
  color: white;
  font-size: 16px;
  margin-bottom: 60px;
  position: relative;
  overflow: hidden;
}

.email__modal-button::before {
  content: "";
  position: absolute;
  top: -100%; /* Увеличиваем начальную точку для лучшего эффекта */
  left: -100%;
  width: 200%;
  height: 200%;
  background: linear-gradient(40deg, transparent 45%, rgba(255, 255, 255, 0.4) 50%, transparent 45%);
  animation: question 4.5s infinite linear;
}

.email__modal-button:hover {
  background-color: #f09e22;
}

.email__modal-social {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  font-size: 0.8em;
}

.email__modal-wa {
  color: inherit;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
}

.email__modal-tg {
  color: inherit;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
}

.email__modal-tg:hover {
  color: #f9d298;
}

.email__modal-wa:hover {
  color: #f9d298;
}

:root {
  --btn-border: 1px solid rgba(255, 255, 255, 0.2);
  --btn-bg: transparent;
  --btn-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 0.5);
  --btn-text-color: #f4f4f4;
  --shine-degree: 120deg;
  --shine-color: rgba(255, 255, 255, 0.2);
  --shine-effect: linear-gradient(
    var(--shine-degree),
    transparent,
    var(--shine-color),
    transparent
  );
  --shine-transition: all 0.65s ease-in-out;
}

.btn {
  position: relative;
  overflow: hidden;
  font-size: 1.5em;
  padding: 0.8em 1.7em;
  border-radius: 4px;
  text-decoration: none;
  border: var(--btn-border);
  color: var(--btn-text-color);
  background: var(--btn-bg);
}
.btn:not(:last-child) {
  margin-bottom: 3rem;
}
.btn::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--shine-effect);
}

.btn-hover::before {
  left: -100%;
  transition: var(--shine-transition);
}
.btn-hover:hover {
  box-shadow: var(--btn-shadow);
}
.btn-hover:hover::before {
  left: 100%;
}

.btn-anim::before {
  animation: shine 5s ease-in-out infinite;
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  11.5044247788%, 100% {
    left: 100%;
    transition-property: left;
  }
}
.btn-alt {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 60px;
  font-size: 1.1rem;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  text-decoration: none;
  border: var(--btn-border);
  color: var(--btn-text-color);
  background: var(--btn-bg);
}
.btn-alt::before, .btn-alt::after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
}
.btn-alt:before {
  background: rgba(255, 255, 255, 0.5);
  width: 60px;
  left: 0;
  filter: blur(30px);
  animation: shine-alt1 5s ease-in-out infinite;
}
.btn-alt:after {
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  left: 30px;
  filter: blur(5px);
  animation: shine-alt2 5s ease-in-out infinite;
}

@keyframes shine-alt1 {
  0% {
    opacity: 0.5;
    transform: translateX(-100px) skewX(-15deg);
    transition-property: opacity, transform;
  }
  11.5044247788%, 100% {
    opacity: 0.6;
    transform: translateX(300px) skewX(-15deg);
    transition-property: opacity, transform;
  }
}
@keyframes shine-alt2 {
  0% {
    opacity: 0;
    transform: translateX(-100px) skewX(-15deg);
    transition-property: opacity, transform;
  }
  11.5044247788%, 100% {
    opacity: 1;
    transform: translateX(300px) skewX(-15deg);
    transition-property: opacity, transform;
  }
}
.container__btn {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 6em;
}

.form__contact {
  display: flex; /* Всегда видимый в DOM для анимации */
  bottom: 15px;
  opacity: 0; /* Скрыт по умолчанию */
  transform: translateY(100%); /* Начальное смещение вниз */
  transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
  width: 96vw;
  position: fixed;
  right: 0;
  left: 0;
  border-radius: 10px;
  background-color: rgba(45, 51, 62, 0.98);
  align-content: center;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 100px;
  font-size: 1.1em;
  z-index: 1000;
  color: white;
  margin: auto;
}

.form__contact-contacts {
  display: flex;
  justify-content: space-between;
}

.form__contact-links {
  margin-right: 30px;
}

.form__contact-links:last-of-type {
  margin-right: 80px;
}

.form__contact-icon {
  fill: #FBFBFC;
  fill-opacity: 0.4;
  transition: all ease-in-out 0.3s;
}

.form__contact-icon:hover {
  fill: #FFAC30;
  fill-opacity: 1;
}

.form__contact-button {
  position: relative;
  border-radius: 10px;
  color: white;
  background-color: #FFAC30;
  transition: all ease-in-out 0.3s;
  box-shadow: 0 4px 13.7px rgba(255, 187, 12, 0.25);
  padding: 10px 80px;
  border: none;
  cursor: pointer;
  overflow: hidden;
}

.form__contact-button:hover {
  background-color: #ff9900;
  transform: scale(1.01);
}

.icon-wrapper {
  display: inline-block;
  border-radius: 50%;
  background-color: transparent;
  animation: pulseBorder 1.5s infinite;
  margin-right: 25px;
}

.call-svg {
  padding: 5px;
  display: block;
  border-radius: 50%;
  background-color: #FFAC30;
  transition: box-shadow 0.3s ease;
  animation: rotate 0.7s infinite;
}

@keyframes pulseBorder {
  0%, 100% {
    transform: scale(1);
    box-shadow: 0 0 0 1px #FFAC30;
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 0 12px rgba(255, 172, 48, 0.3); /* Цвет и размер границы */
  }
}
@keyframes rotate {
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(7deg);
  }
}
.icon-wrapper:focus {
  opacity: 0.95;
  animation: none;
  background-color: #FFAC30;
}

.icon-wrapper:hover {
  opacity: 0.95;
  animation: none;
  background-color: #FFAC30;
}

.icon-wrapper:active {
  opacity: 0.95;
  animation: none;
  background-color: #FFAC30;
}

.contacts .header {
  background-color: #FBFBFB;
}

.contacts {
  background-color: #FBFBFB;
}

.contacts__title {
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 5px;
}

.contact__discr {
  color: #717171;
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 7em;
}

.contact__container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 12px;
  margin-bottom: 5em;
}

.contacts__block-contact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  padding: 40px;
  background: linear-gradient(250deg, #333035 -0.02%, #27262D 33.19%, #201F29 99.58%);
  backdrop-filter: blur(8.164557457px);
}

.block__contact-title {
  font-size: 1.6em;
  font-weight: 600;
  margin-bottom: 10px;
}

.block__contact-discr {
  color: #C9C9C9;
  font-size: 1.1em;
  margin-bottom: 5em;
}

.block__contact-list li {
  margin-bottom: 3em;
  display: flex;
  align-items: center;
}

.block__contact-link {
  display: flex;
  align-items: center;
}

.block__contact-svg {
  margin-right: 10px;
}

.block__contact-svg:hover {
  fill: #fa9c10;
}

.block__contact-adress {
  display: flex;
  align-items: center;
}

.block__contact-social {
  display: flex;
}

.block__lower-item {
  padding: 0 1em 0 0;
}

.contacts__form {
  position: relative;
  width: 60vh;
  margin: auto;
  margin: 4em;
}

.contact__form-block {
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.contact__form-label {
  display: block;
  font-weight: bold;
  margin-bottom: 2em;
  color: #8D8D8D;
}

.contacts input[type=text] {
  margin-right: 1em;
  border: none;
  border-bottom: 1px solid #ccc;
  margin-bottom: 2.5em;
  width: 90%;
  outline: none;
}

.contacts input[type=tel],
input[type=email],
textarea {
  border: none;
  border-bottom: 1px solid #ccc;
  margin-bottom: 2.5em;
  width: 100%;
  outline: none;
}

.contact__form-list {
  margin-bottom: 4em;
  color: #8D8D8D;
}

.contact__form-checkbox {
  display: flex;
  align-items: center;
}

.contacts input[type=radio] {
  display: none;
}

.contact__form-checkbox::before {
  content: "";
  border-radius: 10%;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  margin-right: 1em;
}

input[type=radio]:checked + .contact__form-checkbox::before {
  background: #fa9c10;
}

.contact__form-button {
  position: absolute;
  right: 0;
  padding: 15px 48px;
  font-size: 1.1em;
  background-color: rgb(33, 32, 42);
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.contact__form-button:hover {
  background: #fa9c10;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../fonts/RobotoCondensed-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("../fonts/RobotoCondensed-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Light";
  src: url("fonts/Roboto-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Regular";
  src: url("fonts/Roboto-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Bold";
  src: url("fonts/Roboto-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Roboto Condensed", sans-serif;
  background: #1E1E1E;
  font-size: 16px;
}

h1 {
  margin: 0;
}

ul {
  list-style: none;
}

a:hover {
  color: #FFAC30;
}

.container {
  margin: 0 auto;
  max-width: 1220px;
}

.wrapper {
  padding: 60px 100px 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.fade-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
  z-index: 999;
}

/** Header **/
.rating__container {
  display: flex;
}

.rating__zone {
  position: relative;
  margin-top: -50px;
  overflow: visible;
  background-color: #EF1925;
  color: #FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 26px;
  padding: 0 23px;
  margin-right: 0.9em;
  height: 100px;
}

.rating__zone::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  left: 0;
  bottom: -20px;
  border-top: 21px solid #EF1925;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
}

.rating__main {
  font-size: 26px;
  padding-top: 2em;
}

.rating__block {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stars {
  height: 12px;
  width: 12px;
}

.header-hero-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-image: url(../img/main_2.jpg);
  animation: zoomHero 120s infinite;
}

/* Ключевые кадры для анимации */
@keyframes zoomHero {
  0% {
    background-size: 100%;
    background-position: center;
  }
  100% {
    background-size: 130%;
    background-position: 30% center; /* Смещение изображения вправо */
  }
}
.burger {
  display: none;
}

.header__social-list {
  display: none;
}

/** Hero **/
/** Hero **/
.pagination {
  position: absolute !important;
  width: 100%;
  text-align: center;
  right: 0;
  padding: 0 !important;
  bottom: 30px;
  z-index: 999;
}
.pagination__item {
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  font-size: 0;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  margin: 0 5px;
  transition: 0.2s ease-in-out;
}
.pagination__item.is-current, .pagination__item:hover {
  background-color: #fff;
}

.container {
  position: relative;
  margin: 0 auto;
}
@media (max-width: 699px) {
  .container {
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media (min-width: 700px) and (max-width: 1599px) {
  .container {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
    max-width: 140rem;
  }
}
@media (min-width: 1600px) {
  .container {
    padding-right: 9.5625rem;
    padding-left: 9.5625rem;
    max-width: 144.125rem;
  }
}

.background-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
}

.slideshow {
  position: relative;
  color: #ffffff;
  background-color: #1e1e22;
  overflow: hidden;
  height: 100vh;
  min-height: 400px;
}
.slideshow__slide {
  visibility: hidden;
  transition: visibility 0s 1.7s;
}
.slideshow__slide.is-current {
  visibility: visible;
  transition-delay: 0s;
}
@media (max-width: 699px) {
  .slideshow .slideshow__slide {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 700px) {
  .slideshow .slideshow__slide {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.slideshow__slide-background-load-wrap {
  transition: transform 0.9s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 100%, 0);
  overflow: hidden;
}

.is-loaded .slideshow__slide-background-load-wrap {
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
}

.slideshow__slide.is-prev .slideshow__slide-background-parallax,
.slideshow__slide.is-next .slideshow__slide-background-parallax {
  transform: none !important;
}

.slideshow__slide.is-prev-section .slideshow__slide-background-parallax,
.slideshow__slide.is-next-section .slideshow__slide-background-parallax {
  transform: none !important;
}

.slideshow__slide-background-load {
  transition: transform 0.9s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, -50%, 0);
}

.is-loaded .slideshow__slide-background-load {
  transform: translate3d(0, 0, 0);
}

.slideshow__slide-background-wrap {
  transition: -webkit-transform 1.2s cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  transition: transform 1.2s cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  transition: transform 1.2s cubic-bezier(0.4, 0, 0.2, 1) 0.5s, -webkit-transform 1.2s cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slideshow__slide.is-prev .slideshow__slide-background-wrap {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.slideshow__slide.is-next .slideshow__slide-background-wrap {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.slideshow__slide.is-prev-section .slideshow__slide-background-wrap {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  transition: none;
}

.slideshow__slide.is-next-section .slideshow__slide-background-wrap {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  transition: none;
}

.slideshow__slide-background {
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1) 1.5s;
  transform: scale(1);
  overflow: hidden;
}

.slideshow__slide.is-prev .slideshow__slide-background, .slideshow__slide.is-next .slideshow__slide-background {
  transform: scale(0.5);
  transition-delay: 0s;
}

.slideshow__slide.is-prev-section .slideshow__slide-background, .slideshow__slide.is-next-section .slideshow__slide-background {
  transform: scale(0.5);
  transition-delay: 0s;
  transition: none;
}

.slideshow__slide-image-wrap {
  transition: transform 1.2s cubic-bezier(0.4, 0, 0.2, 1) 0.6s;
  transform: translate3d(0, 0, 0);
}

.slideshow__slide.is-prev .slideshow__slide-image-wrap {
  transform: translate3d(0, 50%, 0);
}

.slideshow__slide-image {
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1) 1.5s;
  transform: scale(1);
}

.slideshow__slide.is-prev .slideshow__slide-image, .slideshow__slide.is-next .slideshow__slide-image {
  transform: scale(1.25);
  transition-delay: 0s;
}

.slideshow__slide.is-prev-section .slideshow__slide-image, .slideshow__slide.is-next-section .slideshow__slide-image {
  transform: scale(1.25);
  transition-delay: 0s;
  transition: none;
}

.slideshow__slide-image::before, .slideshow__slide-image::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.35;
}

.slideshow__slide-image::before {
  background-color: #1e1e22;
}

.slideshow__slide-image::after {
  background: linear-gradient(to bottom, transparent 0%, #1e1e22 100%);
}

.slideshow__slide.is-prev .slideshow_container,
.slideshow__slide.is-next .slideshow_container {
  transform: none !important;
}

.slideshow__slide.is-prev-section .slideshow_container,
.slideshow__slide.is-next-section .slideshow_container {
  transform: none !important;
}

.slideshow__slide-caption-text {
  position: relative;
  height: 100%;
  padding-top: 33vh;
  transition: transform 1.2s cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  transform: translate3d(0, 0, 0);
}

.slideshow__slide.is-prev .slideshow__slide-caption-text {
  transform: translate3d(0, -100%, 0);
}

.slideshow__slide.is-next .slideshow__slide-caption-text {
  transform: translate3d(0, 100%, 0);
}

.slideshow__slide.is-prev-section .slideshow__slide-caption-text {
  transform: translate3d(0, -100%, 0);
  transition: none;
}

.slideshow__slide.is-next-section .slideshow__slide-caption-text {
  transform: translate3d(0, 100%, 0);
  transition: none;
}

.slideshow__slide-caption {
  position: relative;
  height: 100%;
  transform: translate3d(0, 100%, 0);
  transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
}

.is-loaded .slideshow__slide-caption {
  transform: translate3d(0, 0, 0);
}

.slideshow__slide-caption-title {
  line-height: 1;
}
@media (max-height: 500px) {
  .slideshow__slide-caption-title {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 699px) {
  .slideshow__slide-caption-title {
    font-size: 40px;
    margin-bottom: 50px;
  }
  .slideshow.-full .slideshow__slide-caption-title {
    margin-bottom: 30px;
  }
  .o-hsub {
    font-size: 0.5rem;
  }
}
@media (min-width: 700px) {
  .slideshow__slide-caption-title {
    font-size: 5.625rem;
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 700px) and (max-width: 749px) {
  .slideshow__slide-caption-title {
    font-size: 4.375rem;
  }
}
@media (min-width: 1600px) {
  .slideshow__slide-caption-title {
    font-size: 6.25rem;
  }
}
.slideshow__slide-caption-title.-full {
  width: 100%;
}

.slideshow__slide-caption-subtitle {
  display: inline-block;
  padding: 1.875rem 0;
}

.slideshow__slide-caption-subtitle.-load {
  transition: -webkit-transform 0.9s cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  transition: transform 0.9s cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  transition: transform 0.9s cubic-bezier(0.4, 0, 0.2, 1) 0.4s, -webkit-transform 0.9s cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -webkit-transform: translate3d(0, 3.75rem, 0);
  transform: translate3d(0, 3.75rem, 0);
}

.is-loaded .slideshow__slide-caption-subtitle.-load {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

body[data-route-option=prev-section] .slideshow__slide-caption-subtitle.-load, body[data-route-option=next-section] .slideshow__slide-caption-subtitle.-load {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slideshow__slide-caption-subtitle-label {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateZ(0);
  display: inline-block;
}

.o-hsub.-link:hover .slideshow__slide-caption-subtitle-label, .o-hsub-wrap:hover .slideshow__slide-caption-subtitle-label {
  transform: translateX(20px);
}

/* OLD */
.c-header-home_heading {
  line-height: 1;
}

@media (max-height: 500px) {
  .c-header-home_heading {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 699px) {
  .c-header-home_heading {
    font-size: 40px;
    margin-bottom: 150px;
  }
  .c-header-home.-full .c-header-home_heading {
    margin-bottom: 30px;
  }
}
@media (min-width: 700px) {
  .c-header-home_heading {
    font-size: 5.625rem;
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 700px) and (max-width: 749px) {
  .c-header-home_heading {
    font-size: 4.375rem;
  }
}
@media (min-width: 1600px) {
  .c-header-home_heading {
    font-size: 6.25rem;
  }
}
.c-header-home_heading.-full {
  width: 100%;
}

.c-header-home_subheading {
  display: inline-block;
  padding: 1.875rem 0;
}

.c-header-home_subheading.-load {
  transition: transform 0.9s cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  transform: translate3d(0, 3.75rem, 0);
}

.is-loaded .c-header-home_subheading.-load {
  transform: translate3d(0, 0, 0);
}

body[data-route-option=prev-section] .c-header-home_subheading.-load, body[data-route-option=next-section] .c-header-home_subheading.-load {
  transform: translate3d(0, 0, 0);
}

.c-header-home_footer {
  z-index: 3;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.c-header-home_controls, .c-header-home_buttons {
  margin-left: 0;
  letter-spacing: normal;
  font-size: 0;
  transition: -webkit-transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

@media (max-width: 699px) {
  .c-header-home_controls, .c-header-home_buttons {
    padding-bottom: 40px;
  }
}
@media (min-width: 700px) {
  .c-header-home_controls, .c-header-home_buttons {
    padding-bottom: 5.625rem;
  }
}
@media (min-width: 700px) and (max-width: 749px) {
  .c-header-home_controls, .c-header-home_buttons {
    padding-bottom: 3.75rem;
  }
}
.is-loaded .c-header-home_controls, .is-loaded .c-header-home_buttons {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

body[data-route-option=prev-section] .c-header-home_controls, body[data-route-option=prev-section] .c-header-home_buttons, body[data-route-option=next-section] .c-header-home_controls, body[data-route-option=next-section] .c-header-home_buttons {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.c-header-home_controls {
  transition-delay: 0.65s;
}

@media (min-width: 700px) {
  .c-header-home_controls {
    float: left;
  }
}
.c-header-home_buttons {
  transition-delay: 0.75s;
}

@media (max-width: 699px) {
  .c-header-home_buttons {
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (min-width: 1000px) {
  .c-header-home_buttons {
    float: right;
  }
}
@media (max-width: 699px) {
  .c-header-home_button {
    width: 50% !important;
  }
}
@media (min-width: 700px) {
  .c-header-home_button {
    width: 15.625rem;
  }
}
button, .c-header-filters_button,
.o-button {
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background: none;
  color: inherit;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  font: inherit;
  line-height: normal;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button:hover, .c-header-filters_button:hover,
.o-button:hover {
  text-decoration: none;
}

@media (min-width: 1200px) {
  body {
    overflow: auto; /* Измените hidden на auto */
    height: auto; /* Измените height на auto */
  }
}
@media (min-width: 1200px) {
  .o-scroll {
    height: 100%;
  }
}
::-moz-selection {
  background: #0084c0;
  color: #ffffff;
}

::selection {
  background: #0084c0;
  color: #ffffff;
}

img, svg {
  max-width: 100%;
}

a, .o-link {
  color: #ffffff;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

a:hover, .o-link:hover {
  color: #FFAC30;
}

a.-normal, .o-link.-normal {
  color: currentColor;
  text-decoration: none;
}

a.-normal:hover, .o-link.-normal:hover {
  text-decoration: underline;
}

a.-blue:hover, .o-link.-blue:hover {
  text-decoration: none;
  color: #0084c0;
}

a.-hover, .o-link.-hover {
  position: relative;
  text-decoration: none;
  color: #ffffff;
}

a.-hover::after, .o-link.-hover::after {
  content: "";
  position: absolute;
  bottom: -1px;
  right: 0;
  left: 0;
  border-bottom: 1px solid;
  transform: scaleX(0);
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center left;
}

a.-hover:hover::after, .o-link.-hover:hover::after {
  transform: scaleX(1);
}

p {
  margin: 0;
}

.o-wrap {
  overflow: hidden;
}

.o-page.-anim {
  transform: translate3d(0, 9.375rem, 0);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.is-loaded .o-page.-anim {
  transform: translate3d(0, 0, 0);
}

.o-barba, .o-barba_container {
  height: 100%;
}

strong {
  font-weight: 700;
}

.js-parallax {
  transform: translateZ(0);
  will-change: transform;
}

.scroll-content {
  overflow: hidden;
}

.o-blockquote.-nomargin {
  margin: 0;
}

.o-action-link {
  display: block;
  padding-top: 12.8125rem;
  padding-bottom: 7.5rem;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
}

@media (max-width: 699px) {
  .o-action-link {
    font-size: 40px;
    padding-top: 120px;
  }
}
@media (max-width: 1199px) {
  .o-action-link {
    color: #1e1e22;
  }
}
@media (min-width: 700px) {
  .o-action-link {
    font-size: 5.625rem;
  }
}
@media (min-width: 1200px) {
  .o-action-link {
    color: #ffffff;
  }
}
.o-action-link:hover {
  color: #ffffff;
}

.o-action-link_label {
  display: inline-block;
  position: relative;
}

.o-action-link_label::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 0.1875rem solid;
  transform: scaleX(0);
  transform-origin: center left;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.o-action-link:hover .o-action-link_label::after {
  transform: scaleX(1);
}

.o-h, h1, .o-h1, h2, .o-h2, h3, .o-h3, h4, .o-h4, h5, .o-h5, h6, .o-h6 {
  font-weight: 700;
  margin-top: 0;
  line-height: 1.1;
}

@media (max-width: 699px) {
  h1, .o-h1 {
    font-size: 26px;
  }
}
@media (min-width: 700px) {
  h1, .o-h1 {
    font-size: 60px;
  }
}
@media (min-width: 1600px) {
  h1, .o-h1 {
    font-size: 4.375rem;
  }
}
@media (max-width: 1599px) {
  h2, .o-h2 {
    font-size: 1.5625rem;
  }
}
@media (min-width: 1600px) {
  h2, .o-h2 {
    font-size: 2.25rem;
  }
}
h3, .o-h3 {
  font-size: 1.5625rem;
}

h4, .o-h4 {
  font-size: 1rem;
}

h5, .o-h5 {
  font-size: 0.8125rem;
}

h6, .o-h6 {
  font-size: 0.6875rem;
}

.o-hsub {
  font-size: 0.75rem;
  padding: 1.25rem 0;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  font-weight: 500;
}

.o-hsub::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  border-bottom: 1px solid;
  width: 1.5rem;
  background-color: #1e1e22;
  margin-right: 1.125rem;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center left;
}

.o-hsub.-link {
  color: #ffffff;
  text-decoration: none;
}

.o-hsub.-link:hover::before, .o-hsub-wrap:hover .o-hsub.-link::before {
  transform: scaleX(1.5);
}

.o-hsub.-link.-dark {
  color: #1e1e22;
}

.o-hsub.-link.-dark:hover {
  color: #1e1e22;
}

.o-hsub.-h {
  vertical-align: middle;
}

@media (max-width: 699px) {
  .o-hsub.-h {
    display: block;
    margin-top: 20px;
  }
}
@media (min-width: 700px) {
  .o-hsub.-h {
    margin-left: 2.5rem;
  }
}
.o-hsub_label {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateZ(0);
  display: inline-block;
}

.o-hsub.-link:hover .o-hsub_label, .o-hsub-wrap:hover .o-hsub_label {
  transform: translateX(20px);
}

.is-loaded .o-loader {
  visibility: hidden;
  transition-delay: 0.6s;
}

.o-container {
  position: relative;
  margin: 0 auto;
}

@media (max-width: 699px) {
  .o-container {
    padding-right: 40px;
    padding-left: 40px;
  }
  .o-container.-small {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (min-width: 700px) and (max-width: 1599px) {
  .o-container {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
    max-width: 140rem;
  }
}
@media (min-width: 1600px) {
  .o-container {
    padding-right: 9.5625rem;
    padding-left: 9.5625rem;
    max-width: 144.125rem;
  }
}
.o-section {
  position: relative;
}

.o-section.-offset {
  margin-top: -9.375rem;
  background-color: #f6f6f6;
}

.o-section.-padding {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.o-section.-padding-top {
  padding-top: 7.5rem;
}

@media (max-width: 699px) {
  .o-section.-bottom {
    padding-bottom: 60px;
  }
}
@media (min-width: 700px) {
  .o-section.-bottom {
    padding-bottom: 7.5rem;
  }
}
.o-section.-left {
  margin-right: 15rem;
}

.o-section.-right {
  margin-left: 15rem;
}

.o-section.-left-large {
  margin-right: 22.5rem;
}

.o-section.-right.-padding {
  padding-left: 9.5625rem;
}

.o-section_image {
  position: relative;
  overflow: hidden;
}

.o-section_image.-small {
  padding-bottom: 57.144%;
}

.o-section_image.-large {
  padding-bottom: 55%;
}

.o-section_image.-padding-left {
  margin-left: 7.5rem;
}

.o-section_image.-left {
  margin-right: 15rem;
}

@media (max-width: 1599px) {
  .o-section_image.-left {
    margin-left: -7.5rem;
  }
}
@media (min-width: 1600px) {
  .o-section_image.-left {
    margin-left: -9.5625rem;
  }
}
.o-section_image.-right {
  margin-left: 15rem;
}

@media (max-width: 1599px) {
  .o-section_image.-right {
    margin-right: -7.5rem;
  }
}
@media (min-width: 1600px) {
  .o-section_image.-right {
    margin-right: -9.5625rem;
  }
}
.o-section_image img {
  width: 100%;
}

.o-grid {
  margin-left: 0;
  letter-spacing: normal;
  font-size: 0;
}

.o-grid.-margin {
  margin-left: -3.75rem;
}

.o-grid_item {
  display: inline-block;
  padding-left: 0;
  width: 100%;
  vertical-align: top;
  font-size: 1rem;
}

@media (max-width: 699px) {
  .o-grid_item.-button {
    width: 100%;
  }
}
@media (min-width: 700px) and (max-width: 999px) {
  .o-grid_item.-button {
    margin-bottom: 60px;
  }
}
@media (min-width: 1000px) {
  .o-grid_item.-button {
    width: 18.75rem;
  }
}
@media (max-width: 699px) {
  .o-grid_item.-button-content {
    margin-bottom: 30px;
  }
}
@media (min-width: 700px) and (max-width: 999px) {
  .o-grid_item.-button-content {
    margin-bottom: 60px;
  }
}
@media (min-width: 1000px) {
  .o-grid_item.-button-content {
    width: calc(100% - 18.75rem);
  }
}
.o-grid.-margin .o-grid_item {
  padding-left: 3.75rem;
}

@media (min-width: 700px) {
  .o-grid_item.-half {
    width: 50%;
  }
}
@media (min-width: 700px) and (max-width: 1199px) {
  .o-grid_item.-half.-large {
    width: 100%;
  }
}
@media (min-width: 700px) and (max-width: 999px) {
  .o-grid_item.-half.-medium {
    width: 100%;
  }
}
@media (min-width: 700px) and (max-width: 1199px) {
  .o-grid_item.-third {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .o-grid_item.-third {
    width: 33.3333333333%;
  }
}
.o-form {
  padding-bottom: 11.25rem;
}

@media (max-width: 699px) {
  .o-form_item {
    margin-bottom: 35px;
  }
}
@media (min-width: 700px) {
  .o-form_item {
    margin-bottom: 2.9375rem;
  }
}
.o-form_fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

@media (max-width: 699px) {
  .o-form_fieldset {
    margin-bottom: 20px;
  }
}
@media (min-width: 700px) {
  .o-form_fieldset {
    margin-bottom: 3.75rem;
  }
}
.o-form_button {
  text-align: right;
}

.o-label {
  display: block;
  height: 100%;
  color: #b3b3b3;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  padding: 0.875rem;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@media (max-width: 699px) {
  .o-label {
    font-size: 9px;
  }
}
@media (min-width: 700px) {
  .o-label {
    font-size: 0.5625rem;
  }
}
.o-input-wrap .o-label {
  position: absolute;
  bottom: 0;
  left: 0;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.o-input:focus ~ .o-label, .o-select:focus ~ .o-label, .o-textarea:focus ~ .o-label, .o-label.is-active {
  -webkit-transform: translateY(-1.875rem);
  -ms-transform: translateY(-1.875rem);
  transform: translateY(-1.875rem);
}

.o-input.has-error ~ .o-label, .has-error.o-select ~ .o-label, .has-error.o-textarea ~ .o-label {
  color: #cc3d3d;
}

.o-input-wrap {
  position: relative;
}

.o-input, .o-select, .o-textarea {
  padding: 0.875rem;
  background-color: transparent;
  border-bottom: 1px solid #b3b3b3;
  transition: border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@media (max-width: 699px) {
  .o-input, .o-select, .o-textarea {
    font-size: 14px;
  }
}
@media (min-width: 700px) {
  .o-input, .o-select, .o-textarea {
    font-size: 0.875rem;
  }
}
.o-input::-webkit-input-placeholder, .o-select::-webkit-input-placeholder, .o-textarea::-webkit-input-placeholder {
  color: #b3b3b3;
}

.o-input:-ms-input-placeholder, .o-select:-ms-input-placeholder, .o-textarea:-ms-input-placeholder {
  color: #b3b3b3;
}

.o-input::placeholder, .o-select::placeholder, .o-textarea::placeholder {
  color: #b3b3b3;
}

.o-input.-search, .-search.o-select, .-search.o-textarea {
  background-color: transparent;
  color: #ffffff;
  font-weight: 700;
  border-bottom: none;
}

@media (max-width: 699px) {
  .o-input.-search, .-search.o-select, .-search.o-textarea {
    font-size: 26px;
  }
}
@media (min-width: 700px) {
  .o-input.-search, .-search.o-select, .-search.o-textarea {
    font-size: 3.75rem;
  }
}
.o-input.-search::-webkit-input-placeholder, .-search.o-select::-webkit-input-placeholder, .-search.o-textarea::-webkit-input-placeholder {
  color: #000000;
}

.o-input.-search:-ms-input-placeholder, .-search.o-select:-ms-input-placeholder, .-search.o-textarea:-ms-input-placeholder {
  color: #000000;
}

.o-input.-search::placeholder, .-search.o-select::placeholder, .-search.o-textarea::placeholder {
  color: #000000;
}

.-mobile .o-input.-search, .-mobile .-search.o-select, .-mobile .-search.o-textarea {
  font-size: 26px;
  padding: 0;
}

.o-input.-search.-light, .-search.-light.o-select, .-search.-light.o-textarea {
  color: #1e1e22;
}

.o-input.-search.-light::-webkit-input-placeholder, .-search.-light.o-select::-webkit-input-placeholder, .-search.-light.o-textarea::-webkit-input-placeholder {
  color: #b3b3b3;
}

.o-input.-search.-light:-ms-input-placeholder, .-search.-light.o-select:-ms-input-placeholder, .-search.-light.o-textarea:-ms-input-placeholder {
  color: #b3b3b3;
}

.o-input.-search.-light::placeholder, .-search.-light.o-select::placeholder, .-search.-light.o-textarea::placeholder {
  color: #b3b3b3;
}

.o-input.has-error, .has-error.o-select, .has-error.o-textarea {
  border-color: #cc3d3d;
}

.o-input:focus, .o-select:focus, .o-textarea:focus {
  outline: none;
}

.o-input-line {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #1e1e22;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  transition: -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left;
}

.o-input:focus ~ .o-input-line, .o-select:focus ~ .o-input-line, .o-textarea:focus ~ .o-input-line {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.o-input-lines::before, .o-input-lines::after {
  content: "";
  position: absolute;
  bottom: 0;
  border-right: 1px solid #b3b3b3;
  height: 0.375rem;
  transition: border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.o-input.has-error ~ .o-input-lines::before, .has-error.o-select ~ .o-input-lines::before, .has-error.o-textarea ~ .o-input-lines::before, .o-input.has-error ~ .o-input-lines::after, .has-error.o-select ~ .o-input-lines::after, .has-error.o-textarea ~ .o-input-lines::after {
  border-color: #cc3d3d;
}

.o-input-lines::before {
  left: 0;
  transition-delay: 0.3s;
}

.o-input-lines::after {
  right: 0;
}

.o-input:focus ~ .o-input-lines::before, .o-select:focus ~ .o-input-lines::before, .o-textarea:focus ~ .o-input-lines::before, .o-input:focus ~ .o-input-lines::after, .o-select:focus ~ .o-input-lines::after, .o-textarea:focus ~ .o-input-lines::after {
  border-color: #1e1e22;
}

.o-input:focus ~ .o-input-lines::before, .o-select:focus ~ .o-input-lines::before, .o-textarea:focus ~ .o-input-lines::before {
  transition-delay: 0s;
}

.o-input:focus ~ .o-input-lines::after, .o-select:focus ~ .o-input-lines::after, .o-textarea:focus ~ .o-input-lines::after {
  transition-delay: 0.3s;
}

.o-checkbox, .o-radio {
  position: absolute;
  width: 0;
  opacity: 0;
}

.o-checkbox:checked + .o-checkbox-label::after, .o-radio:checked + .o-checkbox-label::after, .o-checkbox:checked + .o-radio-label::after, .o-radio:checked + .o-radio-label::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.o-checkbox-label, .o-radio-label {
  position: relative;
  display: inline-block;
  margin-right: 0.5em;
  line-height: 1.4;
  margin-right: 4.0625rem;
  cursor: pointer;
  padding-top: 0.125rem;
}

@media (max-width: 699px) {
  .o-checkbox-label, .o-radio-label {
    font-size: 12px;
    padding-left: 27px;
  }
}
@media (min-width: 700px) {
  .o-checkbox-label, .o-radio-label {
    font-size: 0.875rem;
    padding-left: 1.1875rem;
  }
}
.o-checkbox-label.-uppsercase, .-uppsercase.o-radio-label {
  text-transform: uppercase;
}

.o-checkbox-label::before, .o-radio-label::before, .o-checkbox-label::after, .o-radio-label::after {
  position: absolute;
  top: 50%;
  left: 0;
  display: inline-block;
  padding: 0;
  content: "";
  border: 1px solid;
  transition: border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@media (max-width: 699px) {
  .o-checkbox-label::before, .o-radio-label::before, .o-checkbox-label::after, .o-radio-label::after {
    width: 12px;
    height: 12px;
    margin-top: -6px;
  }
}
@media (min-width: 700px) {
  .o-checkbox-label::before, .o-radio-label::before, .o-checkbox-label::after, .o-radio-label::after {
    margin-top: -0.28125rem;
    width: 0.5625rem;
    height: 0.5625rem;
  }
}
.o-checkbox-label::after, .o-radio-label::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #1e1e22 transparent transparent transparent;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition: -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@media (max-width: 699px) {
  .o-checkbox-label::after, .o-radio-label::after {
    border-width: 12px 12px 0 0;
  }
}
@media (min-width: 700px) {
  .o-checkbox-label::after, .o-radio-label::after {
    border-width: 0.5625rem 0.5625rem 0 0;
  }
}
.o-checkbox-label.has-error::before, .has-error.o-radio-label::before {
  border-color: #cc3d3d;
}

.o-checkbox-label_text {
  display: inline-block;
  transition: -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.o-checkbox-label:hover .o-checkbox-label_text, .o-radio-label:hover .o-checkbox-label_text {
  -webkit-transform: translateX(0.3125rem);
  -ms-transform: translateX(0.3125rem);
  transform: translateX(0.3125rem);
}

.o-radio-label::before, .o-radio-label::after {
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.o-radio-label::after {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20enable-background%3D%22new%200%200%2013%2013%22%20xml%3Aspace%3D%22preserve%22%3E%3Ccircle%20fill%3D%22%23424242%22%20cx%3D%226.5%22%20cy%3D%226.5%22%20r%3D%226.5%22%2F%3E%3C%2Fsvg%3E");
  background-size: 6px;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  transition: -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
}

.o-radio:checked + .o-radio-label::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.o-select {
  position: relative;
  z-index: 1;
  padding-right: 2.5rem;
}

.o-select:focus {
  border-bottom-color: #1e1e22;
}

.o-select-wrap {
  position: relative;
}

.o-select-wrap::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 2.5rem;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2211.3%22%20viewBox%3D%220%200%2013%2011.3%22%20enable-background%3D%22new%200%200%2013%2011.3%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20fill%3D%22%23b3b3b3%22%20points%3D%226.5%2011.3%203.3%205.6%200%200%206.5%200%2013%200%209.8%205.6%20%22%2F%3E%3C%2Fsvg%3E");
  background-position: center;
  background-size: 10px;
  background-repeat: no-repeat;
  content: "";
  pointer-events: none;
}

.o-textarea-wrap {
  position: relative;
}

.o-textarea {
  min-height: 9.375rem;
}

.o-button {
  position: relative;
  display: inline-block;
  text-align: center;
  border: 1px solid #1e1e22;
  white-space: nowrap;
  font-size: 0;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
}

.o-button:before {
  display: inline-block;
  height: 100%;
  content: "";
  vertical-align: middle;
}

.o-button > * {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  font-size: 1rem;
}

@media (max-width: 699px) {
  .o-button {
    height: 60px;
    padding: 0 20px;
  }
}
@media (min-width: 700px) {
  .o-button {
    height: 3.75rem;
    padding: 0 1.875rem;
  }
}
.o-button::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #1e1e22;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left;
  transition: -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s, -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
}

.o-button:hover {
  color: #ffffff;
  transition-delay: 0s;
}

.o-button:hover::after {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  transition-delay: 0s;
}

@media (min-width: 1200px) {
  .o-button.-anim {
    border-color: transparent;
  }
}
.o-button.-left::after {
  -webkit-transform-origin: center right;
  -ms-transform-origin: center right;
  transform-origin: center right;
}

.o-button.-white {
  border-color: #ffffff;
}

.o-button.-white::after {
  background-color: #ffffff;
}

.o-button.-white:hover {
  color: #000000;
}

@media (max-width: 699px) {
  .o-button.-width {
    width: 100%;
  }
}
@media (min-width: 700px) {
  .o-button.-width {
    width: 15rem;
  }
}
.o-button.-form {
  width: 11.25rem;
}

.o-button.-form:focus {
  color: #ffffff;
  transition-delay: 0s;
}

.o-button.-form:focus::after {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  transition-delay: 0s;
}

.o-button.-square {
  padding: 0;
}

@media (max-width: 699px) {
  .o-button.-square {
    width: 60px;
  }
}
@media (min-width: 700px) {
  .o-button.-square {
    width: 3.75rem;
  }
}
.o-button-group .o-button + .o-button {
  border-left: none;
}

@media (max-width: 699px) {
  .o-button.-padding {
    padding: 1.25rem;
  }
}
@media (min-width: 700px) {
  .o-button.-padding {
    padding: 1.25rem 2.5rem;
  }
}
.o-button_label {
  display: inline-block;
  position: relative;
  transition: -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  line-height: 1.4;
  z-index: 2;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  font-weight: 500;
  font-size: 0.75rem;
}

.o-button:hover .o-button_label {
  -webkit-transform: translateX(0.5rem);
  -ms-transform: translateX(0.5rem);
  transform: translateX(0.5rem);
  transition-delay: 0.075s;
}

.o-button.-left:hover .o-button_label {
  -webkit-transform: translateX(-0.5rem);
  -ms-transform: translateX(-0.5rem);
  transform: translateX(-0.5rem);
}

.o-button.-square:hover .o-button_label {
  -webkit-transform: translateX(0.375rem);
  -ms-transform: translateX(0.375rem);
  transform: translateX(0.375rem);
}

.o-button.-left.-square:hover .o-button_label {
  -webkit-transform: translateX(-0.375rem);
  -ms-transform: translateX(-0.375rem);
  transform: translateX(-0.375rem);
}

.o-button-group {
  margin-left: 0;
  letter-spacing: normal;
  font-size: 0;
}

.o-button_icon {
  position: relative;
  width: 1.1875rem;
  height: 1.1875rem;
  fill: #1e1e22;
  transition: fill 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
}

.o-button.-white .o-button_icon {
  fill: #ffffff;
}

.o-button:hover .o-button_icon {
  fill: #f6f6f6;
  transition-delay: 0s;
}

.o-button.-white:hover .o-button_icon {
  fill: #1e1e22;
}

.o-button_line::before, .o-button_line::after {
  content: "";
  position: absolute;
  background-color: #1e1e22;
}

.is-mobile .o-button_line::before, .is-mobile .o-button_line::after {
  display: none;
}

.o-button_line::before {
  width: 1px;
  top: 0;
  bottom: 0;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  transition: -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.o-button_line::after {
  height: 1px;
  right: 0;
  left: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  transition: -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
}

.o-button_line:first-of-type::before {
  left: 0;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
}

.o-button_line:first-of-type::after {
  top: 0;
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left;
}

.o-button_line:last-of-type::before {
  right: 0;
  -webkit-transform-origin: center top;
  -ms-transform-origin: center top;
  transform-origin: center top;
}

.o-button_line:last-of-type::after {
  bottom: 0;
  -webkit-transform-origin: center right;
  -ms-transform-origin: center right;
  transform-origin: center right;
}

.o-button.is-inview .o-button_line::before {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}

.o-button.is-inview .o-button_line::after {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.o-button.is-inview .o-button_line:first-of-type::before {
  transition-delay: 1.15s;
}

.o-button.is-inview .o-button_line:first-of-type::after {
  transition-delay: 0.1s;
}

.o-button.is-inview .o-button_line:last-of-type::before {
  transition-delay: 0.55s;
}

.o-button.is-inview .o-button_line:last-of-type::after {
  transition-delay: 0.7s;
}

.c-header-home_footer {
  z-index: 3;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.c-header-home_controls, .c-header-home_buttons {
  margin-left: 0;
  letter-spacing: normal;
  font-size: 0;
  transition: -webkit-transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

@media (max-width: 699px) {
  .c-header-home_controls, .c-header-home_buttons {
    padding-bottom: 40px;
  }
}
@media (min-width: 700px) {
  .c-header-home_controls, .c-header-home_buttons {
    padding-bottom: 5.625rem;
  }
}
@media (min-width: 700px) and (max-width: 749px) {
  .c-header-home_controls, .c-header-home_buttons {
    padding-bottom: 3.75rem;
  }
}
.is-loaded .c-header-home_controls, .is-loaded .c-header-home_buttons {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

body[data-route-option=prev-section] .c-header-home_controls, body[data-route-option=prev-section] .c-header-home_buttons, body[data-route-option=next-section] .c-header-home_controls, body[data-route-option=next-section] .c-header-home_buttons {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.c-header-home_controls {
  transition-delay: 0.65s;
}

@media (min-width: 700px) {
  .c-header-home_controls {
    float: left;
  }
}
.c-header-home_buttons {
  transition-delay: 0.75s;
}

@media (max-width: 699px) {
  .c-header-home_buttons {
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (min-width: 1000px) {
  .c-header-home_buttons {
    float: right;
  }
}
@media (max-width: 699px) {
  .c-header-home_button {
    width: 50% !important;
  }
  .hero__padding {
    background-color: white;
  }
  .c-header-home_footer {
    z-index: 3;
    position: absolute;
    right: 0;
    bottom: 125px;
    left: 0;
  }
}
@media (min-width: 700px) {
  .c-header-home_button {
    width: 15.625rem;
  }
}
/** Advantage **/
.advantage {
  padding-top: 75px;
  background-color: white;
  color: #413b3b;
  padding-bottom: 100px;
}

.advantage__upper {
  margin-bottom: 35px;
}

.advantage__upper-block {
  margin-top: -30px;
  position: relative;
  max-width: 60%;
  margin-bottom: 5em;
}

.advantage__upper-text {
  padding-top: 2em;
  font-size: 1.4em;
  font-weight: 300;
  line-height: 121%;
  margin-right: 5em;
}

.advantage__upper-title {
  font-size: 1.8em;
}

.advantage__container {
  background-image: url(../img/map.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.advantage__block {
  margin-top: 5vh;
  position: relative;
  list-style-type: disc;
  padding-right: 60px;
  width: 80%;
  font-weight: bold;
}

.advantage__block-element {
  font-size: 1.3em;
  font-weight: 300;
  margin-bottom: 30px;
}

.advantage__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
}

.advantage__item {
  width: 100%;
  height: 249px;
  box-shadow: 0 0 37px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  margin-bottom: 30px;
  padding: 30px 30px;
  background-color: white;
  transition: all ease-in-out 0.3s;
}

.advantage__item:hover {
  box-shadow: 0 0 37px 0 rgba(0, 0, 0, 0.2);
}

.advantage-min {
  font-size: 0.6em;
}

.advantage__item-title {
  font-weight: 800;
  font-size: 1.3em;
  color: #38647c;
  margin-bottom: 0.9em;
}

.advantage__item-sutitle {
  font-weight: 300;
  font-weight: 0.7em;
}

/**CEO**/
.ceo {
  padding: 100px 0;
  font-family: "Roboto";
  color: #000;
  background-color: white;
}

.ceo__container {
  display: flex;
  align-items: center;
  padding-bottom: 100px;
}

.ceo__block-left {
  margin: 2em 10em 0px 3em;
  width: 60%;
}

.ceo__logo {
  opacity: 0.2;
  margin-bottom: 10px;
}

.ceo__title {
  margin-bottom: 30px;
  font-size: 1.9em;
  font-weight: 400;
  line-height: 1.3em;
  letter-spacing: 0.3px;
}

.ceo__discr {
  text-align: justify;
  font-size: 1em;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: 0.25px;
  margin-bottom: 35px;
}

.ceo__block-picture {
  width: 40%;
}

/**services**/
.services {
  position: relative;
  color: white;
  background: #232323;
  backdrop-filter: blur(5px);
  padding-bottom: 250px;
}

.services__title {
  font-style: normal;
  font-weight: 500;
  font-size: 2.8em;
  margin-bottom: 51px;
}

.services-dectop__contianer {
  display: flex;
  justify-content: space-between;
  outline: none; /* Убираем стандартный контур при фокусе */
  gap: 10px;
  margin-bottom: 10em;
}

.services__desktop-block {
  width: 33%;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  color: #292E31;
  padding: 60px 40px;
  border-radius: 10px;
  position: relative;
  transition: transform 0.5s ease-in-out, opacity 0.5s;
  cursor: pointer; /* Добавляем указатель, чтобы показать, что элементы интерактивны */
}

.services__desktop-block:focus, .services__desktop-block:hover {
  transform: scale(1.05);
  opacity: 1;
  z-index: 2;
}

.services__desktop-block:not(:focus):not(:hover) {
  transform: scale(0.95);
  opacity: 0.9;
}

.services-desktop__upper-block {
  font-weight: 300;
  font-size: 20px;
  padding: 20px 15px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.servies__desktop-list {
  padding: 15px 15px;
  margin-bottom: 30px;
}

.services__desktop-item {
  margin-bottom: 20px;
}

.services__desktop-item-title {
  padding-right: 10px;
  font-size: 1em;
  font-weight: 300;
  line-height: 120%;
}

.services__desktop-item-subtitle {
  font-weight: 300;
  font-size: 0.9em;
  list-style: disc;
  margin: 5px 25px;
  line-height: 120%;
}

.services__desktop-lower {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
}

.services__decktop-button {
  margin-top: auto;
  position: relative;
  border-radius: 10px;
  color: white;
  background-color: #FFAC30;
  transition: all ease-in-out 0.3s;
  box-shadow: 0 4px 13.7px rgba(255, 187, 12, 0.25);
  padding: 15px 60px;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 10px;
}

.services__desktop-block:hover .services__decktop-button::before {
  content: "";
  position: absolute;
  top: -100%; /* Увеличиваем начальную точку для лучшего эффекта */
  left: -100%;
  width: 200%;
  height: 200%;
  background: linear-gradient(40deg, transparent 45%, rgba(255, 255, 255, 0.4) 50%, transparent 45%);
  animation: question 2s infinite linear;
}

.services__desktop-discr {
  font-size: 0.9em;
  color: #701414;
}

/** Expertise **/
.expertise {
  background-color: #FFFFFF;
  justify-content: space-between;
  padding-bottom: 8em;
}

.expertise-title {
  font-weight: 500;
  font-size: 3.5em;
  line-height: 100%;
  margin-bottom: 2em;
}

.expertise-subtitle {
  display: inline-block;
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 20px;
  margin-right: 10px;
}

.expertise-info {
  max-width: 60%;
  font-size: 1em;
  margin-bottom: 50px;
}

.expertise-text {
  margin-bottom: 2em;
  font-weight: 300;
  font-size: 1.2em;
  line-height: 1.5em;
  letter-spacing: -0.055em;
}

.expertise__container {
  position: relative;
  padding: 8em 0;
  z-index: 0;
  overflow: hidden;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  background-color: white;
  width: 10em;
  height: 5px;
  border-radius: 0;
}

.expertise-item img {
  transition: transform 0.5s;
  z-index: 0;
  border: 1px solid rgb(152, 132, 132);
}

.expertise-item img:hover {
  transform: scale(1.7);
  z-index: 1000;
  position: absolute;
}

/** Blog **/
.blog {
  position: relative;
  padding-top: 32px;
  padding-bottom: 45px;
  background-color: #FFFFFF;
}

.blog__title {
  font-weight: 500;
  font-size: 2.8em;
  line-height: 1.5em;
  letter-spacing: -1px;
  color: #152739;
  margin-bottom: 1em;
}

.blog__title-border {
  border-bottom: 10px solid #5E1010;
}

.blog__main {
  height: 370px;
  background-image: url(../img/blog_main.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  color: #FFFFFF;
  border-radius: 15px;
  margin-bottom: 46px;
}

.blog__main-info {
  max-width: 744px;
  padding: 28px 20px 33px 9em;
}

.blog__main-date {
  font-size: 0.9em;
  line-height: 18px;
  text-transform: uppercase;
  color: #FFFFFF;
  opacity: 0.7;
  margin-bottom: 49px;
}

.blog__main-title {
  font-weight: 400;
  font-size: 1.8em;
  line-height: 1em;
  letter-spacing: -1px;
  margin-bottom: 20px;
}

.blog__main-subtitle {
  font-size: 1em;
  line-height: 1.2em;
  letter-spacing: -0.2px;
  color: #FFFFFF;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 3em;
}

.blog__main-button {
  font-size: 1.1em;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: #FFFFFF;
  mix-blend-mode: normal;
  opacity: 0.9;
}

.blog__subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #F7F8FA 0%, #EDF1F5 100%);
  margin-bottom: 64px;
  text-align: justify;
}

.blog__subscribe-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 823px;
  height: 248px;
  padding: 50px 0;
  color: #152739;
}

.blog__subscribe-title {
  font-weight: 400;
  font-size: 1.8em;
  line-height: 28px;
  letter-spacing: -0.777778px;
  color: #152739;
  margin-bottom: 16px;
}

.blog__subscrible-subtitle {
  font-size: 1em;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #152739;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 14px;
}

.blog__subscribe-input {
  text-align: center;
  color: #777470;
  width: 250px;
  height: 61px;
  font-size: 1.25em;
  line-height: 23px;
  border: 1px solid rgba(54, 50, 50, 0.7);
  border-radius: 14px;
  margin-right: 28px;
}

.blog__subscribe-submit {
  text-align: center;
  color: #777470;
  width: 250px;
  height: 61px;
  font-size: 1.25em;
  line-height: 23px;
  border: 1px solid rgba(54, 50, 50, 0.7);
  border-radius: 14px;
  background-color: #ffbd5b;
  transition: all 0.3s ease-in-out;
}

.blog__subscribe-submit:hover {
  border: 1px solid #FFF;
  background-color: #ffa621;
  color: #FFF;
}

.blog__articles-title {
  font-size: 2em;
  line-height: 44px;
  letter-spacing: -1px;
  color: #152739;
  margin-bottom: 20px;
}

.blog__articles-search {
  width: 100%;
  background: #FAFBFC;
  height: 54px;
  padding-left: 50px;
  background: #FAFBFC;
  border: 1px solid #D4D8DF;
  border-radius: 6px;
  margin-bottom: 36px;
}

.blog__articles-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.blog__articles-item {
  margin-bottom: 28px;
}

.blog__articles-picture {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.blog__articles-img {
  display: inline-block;
  height: 15vw;
  width: 100%;
  margin-bottom: 18px;
  border-radius: 10px;
}

.blog__articles-img:hover {
  filter: brightness(0.7);
}

.blog__articles-data {
  display: block;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: #152739;
  mix-blend-mode: normal;
  opacity: 0.4;
  margin-bottom: 10px;
}

.blog__articles-title {
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1em;
  letter-spacing: -0.403846px;
  color: #000000;
  margin-bottom: 1.4em;
}

.blog__articles-button {
  font-size: 1.1em;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: #FFAC30;
  mix-blend-mode: normal;
  opacity: 0.9;
  transition: color 0.2s ease-in-out;
}

.blog__articles-button:hover {
  color: rgb(8, 4, 121);
}

/** FAQ **/
.faq {
  background: #282731;
  backdrop-filter: blur(5px);
  color: white;
  padding-top: 35px;
  padding-bottom: 100px;
}

.faq__title {
  font-size: 2.8em;
  line-height: 2.8em;
  text-transform: capitalize;
  margin-bottom: 2em;
}

.faq__subtitle {
  font-size: 4em;
  line-height: 94.5%;
  letter-spacing: -3.2px;
  margin-bottom: 33px;
}

.faq__discr {
  font-size: 1.1em;
  line-height: 156.5%;
  margin-bottom: 75px;
}

.faq__item {
  margin-bottom: 5px;
}

.faq__item-info {
  visibility: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.faq__item-info.ac-active {
  visibility: visible;
  max-height: 360px;
}

.faq__item-title {
  margin-bottom: 30px;
}

.faq__item-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
  padding: 40px 20px;
  border: none;
  border-radius: 8px;
  background: rgba(217, 217, 217, 0.28);
  font-size: 1em;
}

.faq__item-text {
  text-align: left;
  max-width: 75%;
}

.faq__item-btn {
  width: 25px;
  height: 25px;
}

.faq__item-discr {
  min-height: 248px;
  font-size: 1.2em;
  line-height: 156.5%;
  padding: 54px 20px;
  border-radius: 8px;
  background: #181D24;
  margin-bottom: 20px;
}

.faq__questions-block {
  padding: 56px 0 43px 60px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(8.5px);
  margin-bottom: 50px;
}

.faq__questions-title {
  font-size: 2.25em;
  line-height: 43px;
  margin-bottom: 8px;
}

.faq__qustions-subtitle {
  color: #FFF;
  font-size: 1.1em;
  line-height: 32px;
  margin-bottom: 24px;
}

.faq__questions-form {
  max-width: 600px;
  padding-right: 45px;
}

.faq__questions-input {
  padding: 15px;
  color: white;
  font-size: 1em;
  width: 100%;
  height: 64px;
  border: none;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 41px 0px rgba(0, 0, 0, 0.13);
  backdrop-filter: blur(2px);
  margin-bottom: 16px;
}

.faq__questions-text {
  font-size: 1.1em;
  line-height: 52px;
}

.faq__questions-area {
  padding: 15px;
  color: white;
  font-size: 1em;
  width: 100%;
  border-radius: 14px;
  background: var(--white-white-10, rgba(255, 255, 255, 0.1));
  box-shadow: 0px 4px 41px 0px rgba(0, 0, 0, 0.13);
  backdrop-filter: blur(2px);
  margin-bottom: 48px;
}

.faq__questions-submit {
  border: none;
  color: #534E4E;
  font-size: 1.25em;
  width: 40%;
  height: 63.078px;
  border-radius: 14px;
  background: #FFAC30;
  transition: all 0.2s ease-in-out;
}

.faq__questions-submit:hover {
  background: #fa9c10;
  color: #FFF;
  border: 2px solid #FFF;
}

.faq__questions-submit:focus {
  background: #fa9c10;
  color: #FFF;
  border: 2px solid #FFF;
}

.faq__questions-submit:active {
  background: #fa9c10;
  color: #FFF;
  border: 2px solid #FFF;
}

.faq__slogan {
  color: #FFF;
  text-align: center;
  font-size: 1.6em;
  line-height: 2em;
  text-transform: uppercase;
  padding-left: 40px;
}

/**Partners**/
.partners {
  background: #FFF;
  padding-top: 56px;
  padding-bottom: 67px;
}

.partners__title {
  color: #1E252F;
  text-align: center;
  font-size: 2.8em;
  line-height: 110.5%;
  margin-bottom: 136px;
}

.partners__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.parners__item {
  width: 25%;
  margin-bottom: 60px;
}

.partners__img {
  margin: 0 auto;
}

/** Calc **/
.calc {
  background-color: #FFF;
}

.result {
  width: 300px;
  height: 150px;
  background-color: #5E6B7E;
  color: #ffffff;
}

/**Popup Calculator**/
.hidden {
  display: none;
}

.popup-visible {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#calculator__popup {
  width: 80%;
  height: 50vh;
}

/** About-US **/
.about-us {
  font-family: Roboto;
  background-color: #FFF;
}

.about-us.header {
  background: white;
}

.about-us__hero {
  background-image: url(../img/articles/about-us_2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  color: white;
  text-align: center;
  filter: grayscale(50%);
  z-index: 1;
}

.about-us__hero-title {
  margin: 150px 35px;
  font-size: 4em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  z-index: 5;
}

.about-us__hero-discr {
  margin: 0 35px;
  font-size: 4em;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.374px;
  margin-bottom: 40px;
}

.about-us__hero-link {
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.about-us__video {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us__video-title {
  font-size: 4em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.about-us__main {
  margin-bottom: 100px;
}

.about-us__main-title {
  font-size: 2.4em;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.3px;
}

.about-us__main-discr {
  color: #343B40;
  font-size: 1.8em;
  font-style: normal;
  font-weight: 400;
  line-height: 159%;
  letter-spacing: -0.2px;
  margin-bottom: 3em;
}

.about-us__main-upper {
  display: flex;
  justify-content: space-between;
}

.about-us__main-text {
  padding: 50px 80px;
  width: 50%;
}

.about-us__main-picture {
  width: 50%;
  background-image: url(../img/articles/about-us_2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.main__text-title {
  color: #353434;
  font-size: 2.3em;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.main__text-subtitle {
  color: #353434;
  font-size: 1.3em;
  font-style: normal;
  font-weight: 500;
}

.main__text-list {
  list-style: disc;
  list-style-position: inside;
  margin-bottom: 2em;
}

.main__text-item {
  font-size: 1.4em;
  font-style: normal;
  font-weight: 500;
  line-height: 200%;
  letter-spacing: -0.2px;
}

.about-us__main-lower {
  display: flex;
  justify-content: space-between;
}

.about-us__main-img {
  width: 100%;
}

.about-us__services-title {
  text-align: center;
  font-size: 2em;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  margin-bottom: 2em;
}

.about-us__services-list {
  margin-bottom: 4em;
  list-style: disc;
}

.about-us__services-item {
  font-size: 1.3em;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.2px;
}

.about-us__services-discr {
  font-size: 1.4em;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.2px;
  margin-bottom: 100px;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media (max-width: 1500px) {
  .page__body {
    font-size: 12px;
  }
  .wrapper {
    padding: 50px 50px;
  }
  .header__upper.wrapper {
    padding: 15px 50px 15px;
  }
  /**Articles-list**/
  .article__item:nth-child(1) {
    grid-column: 1/4;
    grid-row: 1/1;
  }
  .article__item:nth-child(2) {
    grid-column: 4/8;
    grid-row: 1/1;
  }
  .article__item:nth-child(3) {
    grid-column: 8/13;
    grid-row: 1/1;
  }
  .article__item:nth-child(4) {
    grid-column: 1/13;
    grid-row: 2/2;
  }
  .article__item:nth-child(5) {
    grid-column: 1/6;
    grid-row: 3/3;
  }
  .article__item:nth-child(6) {
    grid-column: 6/13;
    grid-row: 3/3;
  }
  .article__item:nth-child(7) {
    grid-column: 1/13;
    grid-row: 4/4;
  }
  .article__item:nth-child(8) {
    grid-column: 1/7;
    grid-row: 5/5;
  }
  .article__item:nth-child(9) {
    grid-column: 7/13;
    grid-row: 5/5;
  }
  .article__item:nth-child(10) {
    grid-column: 1/13;
    grid-row: 6/6;
  }
  .article__item:nth-child(11) {
    grid-column: 1/7;
    grid-row: 7/7;
  }
  .article__item:nth-child(12) {
    grid-column: 7/13;
    grid-row: 7/7;
  }
  .article__item:nth-child(13) {
    grid-column: 1/13;
    grid-row: 8/9;
  }
}
@media (max-width: 1024px) {
  .wrapper {
    padding: 50px 50px;
  }
  .sidebar__list {
    display: none;
  }
  .header__upper-item:nth-child(2) {
    display: none;
  }
  .header__upper-item:nth-child(4) {
    display: none;
  }
  .header-hero-container {
    height: 1200px;
  }
  .hero__link-block {
    display: none;
  }
  .advantage {
    padding-top: 0;
  }
  .advantage__upper {
    justify-content: center;
  }
  .advantage__upper-block {
    margin-top: 30px;
    max-width: 100%;
  }
  .advantage__upper-text {
    padding-top: 0;
    margin-bottom: 50px;
    margin-right: 0;
  }
  .advantage picture {
    display: none;
  }
  .advantage__upper-title {
    font-size: 2.5em;
    position: relative;
    margin-bottom: 30px;
  }
  .advantage__block {
    display: none;
  }
  .advantage__list {
    grid-column-gap: 10px;
  }
  .advantage__item {
    height: auto;
    padding: 30px 15px;
    border-radius: 5px;
  }
  .advantage__item-title {
    font-size: 2em;
  }
  .project {
    display: none;
  }
  .section__upper-item:nth-child(2) {
    display: none;
  }
  .ceo__background::after {
    margin-bottom: 5px;
  }
  .ceo__container {
    display: flex;
    flex-direction: column-reverse;
  }
  .ceo__logo {
    display: none;
  }
  .ceo__block-picture {
    width: 80%;
    margin-bottom: 35px;
  }
  .ceo__block-left {
    margin-right: 0;
    width: 100%;
  }
  .request {
    margin: 0;
  }
  .calculator__container {
    flex-direction: column-reverse;
  }
  .calculator__block {
    width: 100%;
  }
  .calculator__group {
    max-width: 350px;
    border: none;
    padding: 0;
  }
  .calculator-number {
    display: none;
  }
  .calculator__main-block {
    grid-template-columns: repeat(1, 1fr);
  }
  .calculator__subtitle:nth-child(1) {
    display: none;
  }
  .article.flex {
    flex-direction: column;
  }
  .footer__main {
    width: 80%;
  }
  .team__expertise {
    display: flex;
    flex-direction: row;
  }
  .team__expertise-info {
    flex: 1;
    /* Здесь может быть дополнительный CSS для текста */
  }
  .team__expertise-img-wrapper {
    flex: 1;
    overflow: hidden;
    scroll-snap-type: x mandatory; /* Включаем прокрутку с выравниванием по оси X */
  }
  .team__expertise-img-list {
    display: flex;
    scroll-snap-type: x mandatory; /* Включаем прокрутку с выравниванием по оси X */
  }
  .team__expertise-img {
    flex: 0 0 100%; /* Каждое изображение занимает 100% ширины контейнера */
    scroll-snap-align: start; /* Выравнивание для прокрутки */
  }
  .blog__main {
    height: auto;
    margin-bottom: 46px;
  }
  /**Articles**/
  .header.article {
    background-color: #FFF;
    border-bottom: 2px solid rgba(47, 34, 34, 0.2);
    padding: 0;
  }
  .article .section__upper-list {
    display: flex;
    background-color: #FFF;
  }
  .article .wrapper {
    padding: 0 25px;
  }
  .article.flex {
    padding: 25px;
  }
  .article__aside {
    max-width: 100%;
    margin-top: 50px;
  }
  .article__main {
    margin-right: 0px;
  }
  .article__title {
    margin-top: 20px;
  }
  .email__modal_close-button {
    top: 5vh; /* Меньшее расстояние от верхнего края */
    right: 5vh; /* Меньшее расстояние от правого края */
    font-size: 1.5em; /* Меньший размер иконки */
  }
  .email__modal-container {
    padding-top: 10vh;
    max-width: 80vw; /* Больший процент использования ширины экрана */
  }
  .email__modal-form {
    padding: 60px 30px; /* Меньшие отступы внутри формы */
  }
}
@media (max-width: 640px) {
  .header__upper.wrapper {
    padding: 25px;
  }
  .burger {
    display: flex;
    --burger-width: 30px;
    --burger-height: 30px;
    --burger-line-height: 2px;
    position: relative;
    border: none;
    padding: 0;
    width: var(--burger-width);
    height: var(--burger-height);
    color: #FFF;
    background-color: transparent;
    cursor: pointer;
  }
  .burger::before, .burger::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: var(--burger-line-height);
    background-color: currentColor;
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
  }
  .burger::before {
    top: 0;
  }
  .burger::after {
    top: calc(100% - var(--burger-line-height));
  }
  .burger__line {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: var(--burger-line-height);
    background-color: currentColor;
    transform: translateY(-50%);
    transition: transform 0.3s ease-in-out;
  }
  .burger--active::before {
    top: 50%;
    transform: rotate(45deg);
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
  }
  .burger--active::after {
    top: 50%;
    transform: rotate(-45deg);
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
  }
  .burger--active .burger__line {
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
  }
  .page__body {
    font-size: 9px;
  }
  .wrapper {
    padding: 35px 35px;
  }
  .sidebar {
    display: none;
  }
  .section__upper-list {
    display: none;
  }
  .header {
    padding: 17px 0;
    background: rgba(252, 252, 252, 0.05);
    backdrop-filter: blur(5px);
  }
  .header__lower-link {
    font-size: 1em;
  }
  .header__upper-item:not(:first-child) {
    display: none;
  }
  .header__lower {
    display: none;
    opacity: 0;
    transition: opacity 0.9s ease-in-out;
  }
  .header__lower.active {
    display: flex;
    opacity: 1;
    flex-direction: column;
    z-index: 3;
    height: 100vh;
  }
  .header__lower-list {
    display: flex;
    height: 50vh;
    flex-direction: column;
    font-size: 2.4em;
    font-weight: 400;
    align-content: flex-start;
    align-items: flex-start;
  }
  .header__social-list {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }
  .header__social-icon {
    fill: rgb(255, 255, 255);
    width: 38px;
    height: 34px;
  }
  .header__social-icon {
    transition: fill 0.2s ease-in-out;
  }
  .header__social-icon:hover {
    fill: white;
  }
  .header-hero-container {
    min-height: 0;
    height: 100vh;
    background-size: cover;
    background-image: url(../img/hero__mobile.jpg);
  }
  .hero__img {
    position: absolute;
    right: auto;
    left: 35px;
    width: 55%;
  }
  .hero__block {
    color: white;
    margin-top: 30em;
    max-width: 100%;
    text-align: center;
  }
  .hero__title {
    margin-bottom: 12px;
  }
  .hero__subtitle {
    font-size: 1.5em;
  }
  .hero__link-block {
    display: none;
  }
  .btn {
    font-size: 2em;
    padding: 1.5em 4.7em;
  }
  .hero__lower {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: none;
    backdrop-filter: blur(5px);
  }
  .hero__lower-text {
    display: none;
  }
  .hero__lower-button {
    font-size: 2em;
    font-style: normal;
    font-weight: 700;
    line-height: 115.688%;
    letter-spacing: 0.7px;
    margin: 0 auto;
    width: 100%;
  }
  .advantage__list {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
  }
  .container__btn {
    justify-content: center;
  }
  /** CEO **/
  .ceo__container {
    display: flex;
    flex-direction: column-reverse;
    align-content: center;
  }
  .ceo__block-img {
    margin-bottom: 15px;
  }
  .ceo__logo {
    display: none;
  }
  .ceo__block-left {
    margin-right: 0;
    width: 100%;
  }
  /** info **/
  .info__list .info__item {
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: none;
    padding: 1em 1em 4em 1em;
  }
  .info__middle {
    display: none;
  }
  .info__lower {
    display: none;
  }
  /** Services **/
  .services-desktop {
    display: none;
  }
  .services {
    display: none;
    position: relative;
    padding: 50px 0 36px;
    color: white;
    background-color: #11101D;
    backdrop-filter: blur(5px);
  }
  .services__title {
    font-size: 2.5em;
    padding-bottom: 5px;
    margin-bottom: 51px;
  }
  .services__title:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40%;
    height: 4px;
    background-color: #FFF;
  }
  .services__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .services__item {
    margin-bottom: 35px;
    background: linear-gradient(144.39deg, #FFFFFF -278.56%, #6D6D6D -78.47%, #11101D 91.61%);
    backdrop-filter: blur(3.85604px);
    border-radius: 15px;
  }
  .service__item-link {
    padding: 1.8em 3.5em;
  }
  .services__item-discr {
    font-weight: 400;
    font-size: 1.1em;
    line-height: 22px;
    opacity: 0.8;
  }
  .services__item-title {
    font-weight: 700;
    font-size: 2em;
    line-height: 109%;
    margin-bottom: 10px;
  }
  .services__item-subtitle {
    max-width: 225px;
    font-size: 1.9em;
    line-height: 138%;
    opacity: 0.8;
  }
  .services__item-price {
    font-size: 2.3em;
  }
  .services__container {
    display: none;
  }
  .request {
    margin: 0;
  }
  .request-title {
    font-size: 1.5em;
  }
  .form {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .form-label {
    margin: 0 0 20px 0;
  }
  .form-input {
    padding: 1em 3.5em;
  }
  .form-submit {
    width: 100%;
  }
  .experts {
    display: none;
  }
  .team {
    display: none;
  }
  .team__subtitle {
    background: none;
  }
  .team__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: "A A B" "A A C" "D E F";
    gap: 20px;
  }
  .team__item {
    border-radius: 6px;
  }
  .team__item-img {
    margin-bottom: 3px;
    width: 100%;
  }
  .team__item:nth-child(1) {
    display: inline-block;
    grid-area: A;
  }
  .team__item:nth-child(2) {
    grid-area: B;
  }
  .team__item:nth-child(3) {
    grid-area: C;
  }
  .team__item:nth-child(4) {
    grid-area: D;
  }
  .team__item:nth-child(5) {
    grid-area: E;
  }
  .team__item:nth-child(6) {
    grid-area: F;
  }
  .team__item-name {
    font-size: 1.7em;
  }
  .team__item-position {
    font-size: 1.2em;
  }
  .team__expert-discr {
    margin-bottom: 1.5em;
  }
  .team__expertise {
    flex-direction: column;
  }
  .team__expertise-text {
    margin-bottom: 1.3em;
    font-size: 2.2em;
  }
  .team__expertise-info {
    max-width: 100%;
  }
  .team__expertise-img {
    max-width: 100%;
  }
  .blog__main {
    height: auto;
    background-image: url(../img/blog_main.jpg);
    background-repeat: no-repeat;
    background-size: auto;
    color: #FFFFFF;
    border-radius: 15px;
    margin-bottom: 2em;
  }
  .blog__subscribe-input {
    width: 100%;
    height: 40px;
    margin-bottom: 1.2em;
  }
  .blog__subscribe-submit {
    width: 100%;
    height: 40px;
  }
  /** Ezxpertixe**/
  .expertise-item img:hover {
    transform: scale(1);
    z-index: 1;
  }
  /** Price **/
  .price__item-button {
    padding: 10px 10px;
  }
  .price__item-title {
    margin-bottom: 0;
  }
  .rs-label {
    width: 70px;
    height: 70px;
    font-size: 1.5em;
  }
  .calculator__subtitle {
    margin-bottom: 0;
  }
  .calculator__range {
    flex-wrap: wrap;
  }
  .rs-range {
    width: 350px;
  }
  .calculator__block {
    width: 100%;
    padding: 0 25px;
    margin-bottom: 25px;
  }
  .calculator__block-info {
    display: none;
  }
  .calculator__result {
    font-size: 2em;
    padding: 20px 0 50px 0;
  }
  .calculator__button {
    border-radius: 10px;
    width: 170px;
    height: 40px;
  }
  /** Material **/
  .materials-container {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .team__pictire-block {
    width: 100%;
  }
  .materials-info {
    width: 100%;
  }
  .programs {
    display: none;
  }
  .contact__form {
    display: none;
  }
  /** Faq **/
  .faq__discr {
    margin-bottom: 25px;
  }
  .faq__item-button {
    padding: 10px 15px;
  }
  .faq__questions-submit {
    width: 100%;
    height: 40px;
  }
  .faq__item-title {
    margin-bottom: 10px;
  }
  .faq__questions-block {
    padding: 40px 20px;
  }
  .partners__title {
    margin-bottom: 30px;
  }
  .parners__item {
    width: 10%;
    margin-bottom: 60px;
  }
  .footer {
    padding: 5px 0 10px;
    position: relative;
    background-color: #23222C;
    color: #FFF;
    background-image: url(../img/slogan.svg), url(../img/Exmlid.svg);
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: 0 0, 20% 30%;
  }
  .footer__main {
    width: 80%;
  }
  /**Articles**/
  .article__item-big .article__item-block {
    display: none;
  }
  .article__item-article {
    width: 100%;
  }
  /**About US**/
  .about-us__main-upper {
    flex-direction: column;
  }
  .about-us__main-lower {
    flex-direction: column;
  }
  .about-us__main-picture {
    width: 100%;
    height: 50vh;
  }
  .about-us__main-text {
    width: 100%;
  }
  .form__contact {
    width: 100vw;
    bottom: 0;
    height: 80px;
    padding: 0 10px;
    border-radius: 0;
    justify-content: center;
  }
  .form__contact p {
    display: none;
  }
  .form__contact-links {
    margin-right: 10px;
  }
  .form__contact-links:last-of-type {
    margin-right: 10px;
  }
  .form__contact-button {
    padding: 10px 40px;
    border-radius: 5px;
  }
  .form__contact-button:hover {
    background-color: #ff9900;
    transform: scale(1.01);
  }
}