.calculator {
    color: #FFF;
    background: linear-gradient(161deg, #3E3D43 31.23%, #12101D 99.81%);
    backdrop-filter: blur(5px);
    padding-top: 25px;
    padding-bottom: 25px;
}

.calculator__title {
    font-size: 2.8em;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 1em;
}

.calculator__title-color {
    color: #FFAC30;
}

.price-info {
    text-align:justify;
    font-size: 1.4em;
    line-height: 156.5%;
    margin-bottom: 2em;
    font-weight: 300;
}

.price__discr {
    font-size: 1.1em;
    line-height: 156.5%;
    margin-bottom: 75px;
  }
  
  .price__item {
    margin-bottom: 5px;
  }
  
  .price__item-info {
    visibility: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    margin-bottom: 50px;
  }
  
  .price__item-info.ac-active {
    visibility: visible;
    max-height: 800px;
  }
  
  .price__item-title {
    margin-bottom: 30px;
  }
  
  .price__item-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;
    padding: 40px 20px;
    border: none;
    border-radius: 8px;
    background: rgba(22, 25, 31, 0.6);
    font-size: 1em;
  }

  .price__item-button:hover {
   outline: 2px solid white;
  }

  
  .price__item-discr {
    min-height: 248px;
    font-size: 1.2em;
    line-height: 156.5%;
    padding: 54px 20px;
    border-radius: 8px;
    background: rgba(24, 29, 36, 0.7);
    margin-bottom: 20px;
  }
  

.calculator__subtitle {
    font-size: 2.8em;
    margin-bottom: 1em;
}

.calculator__discr {
    font-size: 1.5em;
    margin-bottom: 1em
}


.container__position {
    align-self: flex-end;
    padding-bottom: 30px;
}

.calculator__main-block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px; 

}

.container__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1em;
}

.box-minmax {
    margin-top: 30px;
    width: 75%;
    display: flex;
    justify-content: space-between;
    font-size: 1.8em;
    color: #FFFFFF;

    span:first-child {
        margin-left: 10px;
    }
}


.rs-range {
    margin-top: 29px;
    width: 600px;
    -webkit-appearance: none;

    &:focus {
        outline: none;
    }

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 1px;
        cursor: pointer;
        box-shadow: none;
        background: #ffffff;
        border-radius: 0px;
        border: 0px solid #010101;
    }

    &::-moz-range-track {
        width: 100%;
        height: 1px;
        cursor: pointer;
        box-shadow: none;
        background: #ffffff;
        border-radius: 0px;
        border: 0px solid #010101;
    }

    &::-webkit-slider-thumb {
        box-shadow: none;
        border: 0px solid #ffffff;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
        height: 42px;
        width: 22px;
        border-radius: 22px;
        background: rgba(255, 255, 255, 1);
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -20px;
    }

    &::-moz-range-thumb {
        box-shadow: none;
        border: 0px solid #ffffff;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
        height: 42px;
        width: 22px;
        border-radius: 22px;
        background: rgba(255, 255, 255, 1);
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -20px;
    }

    &::-moz-focus-outer {
        border: 0;
    }
}

.rs-label {

    position: relative;
    transform-origin: center center;
    display: block;
    width: 100px;
    height: 80px;
    background: transparent;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    padding-top: 22px;
    box-sizing: border-box;
    border: 2px solid #FFAC30;
    margin-top: 20px;
    margin-left: -50px;
    left: attr(value);
    color: #fff;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 1.4em;

    &::after {
        content: "позиций";
        display: block;
        font-size: 1em;
        letter-spacing: 0.07em;
        margin-top: -2px;
    }

}


.calculator-number {
    font-size: 2.5em;
    font-weight: 900;
    color: rgba(255, 174, 53, 1);
    padding-right: 15px;
}


.calculator__position {
    font-size: 1.5em;
    margin-right: 1em;

}

.calculator__position-input {
    max-width: 287px;
    max-height: 60px;
    font-size: 1.8em;
    text-align: center;
    padding: 5px;
    max-width: 287px;
    max-height: 60px;
    font-size: 1.8em;
    text-align: center;
    margin-bottom: 1em;
    background-color: inherit;
    color: #FFFF;
    border-color: #FFF;
}

.calculator__data-title {
    font-size: 1.6em;
    margin-bottom: 0.8em;
}

.calculator__input {}

.calculator__label {
    display: flex;
    align-items: center;
    max-width: 450px;
    font-size: 1.3em;
    font-style: normal;
    font-weight: 500;
}

.calculator__work-title {
    font-size: 1.6em;
    margin-bottom: 0.8em;
}

.calculator__expertise-title {
    font-size: 1.6em;
    margin-bottom: 0.8em;
}

.calculator__katz-title {
    font-size: 1.6em;
    margin-bottom: 0.8em;
}

.calculator__button {
    border: none;
    border-radius: 14px;
    background: #FFAC30;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 270px;
    height: 60px;
    color: #000;
    text-align: center;
    font-size: 1.25em;
    transition: all .3s ease-in-out;
    margin-bottom: 2.5em;
}

.calculator__button:hover {
    background: #fa9c10;
    color: #FFF;
    border: 2px solid #FFF;
}

.calculator__button:focus {
    background: #fa9c10;
    color: #FFF;
    border: 2px solid #FFF;
}

.calculator__button:active {
    background: #fa9c10;
    color: #FFF;
    border: 2px solid #FFF;
}

.calculator__result {
    font-size: 1.8em;
    padding-bottom: 5em;
}


.calculator__group {
    padding: 20px 60px;
    border: 1px solid #FFFF;
    display: block;
    margin-bottom: 4em;
}


.calculator input[type="radio"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.calculator__label {
    position: relative;
    margin-right: 1em;
    padding-left: 2em;
    padding-right: 1em;
    line-height: 2;
    cursor: pointer;

    &:before {
        box-sizing: border-box;
        content: " ";
        position: absolute;
        top: 0.3em;
        left: 0;
        display: block;
        width: 1.4em;
        height: 1.4em;
        border: 2px solid white;
        border-radius: .25em;
        z-index: -1;
    }
}

/* Checked */
.calculator input[type="radio"]:checked+label {
    padding-left: 1em;
    color: black;

    &:before {
        top: 0;
        width: 100%;
        height: 2em;
        background: white;
    }
}

/* Transition */
.calculator label,
label::before {
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
}



.price__result {
    text-align: justify;
    font-size: 1.4em;
    line-height: 156.5%;
    margin-bottom: 75px;
    font-weight: 300;
text-align:justify;
}







/* style.css */

#quizContainer {
    display: none;
}





fieldset {
    margin-bottom: 20px;
}


#result {
    margin-top: 20px;
}

.question {
    display: none;
}

.question.active {
    display: block;
}






