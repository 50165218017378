.icon-wrapper {
    display: inline-block;
    border-radius: 50%;
    background-color: transparent;
    animation: pulseBorder 1.5s infinite;
    margin-right: 25px;
}

.call-svg {
    padding: 5px;
    display: block;
    border-radius: 50%;
    background-color: #FFAC30;
    transition: box-shadow 0.3s ease;
    animation: rotate 0.7s infinite;
}


@keyframes pulseBorder {
    0%, 100% {
        transform: scale(1);
        box-shadow: 0 0 0 1px #FFAC30;
    }
    50% {
        transform: scale(1.05); 
        box-shadow: 0 0 0 12px rgba(255, 172, 48, 0.3); /* Цвет и размер границы */
    }
}

@keyframes rotate {
    0%, 100% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(7deg);
    }
}


.icon-wrapper:focus {
    opacity: 0.95;
    animation: none; 
    background-color: #FFAC30;
}

.icon-wrapper:hover {
    opacity: 0.95;
    animation: none; 
    background-color: #FFAC30;
}

.icon-wrapper:active {
    opacity: 0.95;
    animation: none; 
    background-color: #FFAC30;
}