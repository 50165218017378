.form__contact {
    display: flex; /* Всегда видимый в DOM для анимации */
    bottom: 15px;
    opacity: 0; /* Скрыт по умолчанию */
    transform: translateY(100%); /* Начальное смещение вниз */
    transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
    width: 96vw;
    position: fixed;
    right: 0;
    left: 0;
    border-radius: 10px;
    background-color: rgb(45, 51, 62, 0.98);
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 100px;
    font-size: 1.1em;
    z-index: 1000;
    color: #FFFF;
    margin: auto;
}



.form__contact-contacts {
    display: flex;
    justify-content: space-between;
}

.form__contact-links {
    margin-right: 30px;
}
.form__contact-links:last-of-type {
    margin-right: 80px;
}

.form__contact-icon {
    fill: #FBFBFC;
    fill-opacity: 0.4;
    transition: all ease-in-out 0.3s;
}

.form__contact-icon:hover {
    fill: #FFAC30;
    fill-opacity: 1;
}



.form__contact-button {
    position: relative;
    border-radius: 10px;
    color: #FFFF;
    background-color: #FFAC30;
    transition: all ease-in-out 0.3s;
    box-shadow: 0 4px 13.7px rgba(255, 187, 12, 0.25);
    padding: 10px 80px;
    border: none;
    cursor: pointer;
    overflow: hidden;
}

.form__contact-button:hover {
    background-color: #ff9900;
    transform: scale(1.01);
}

