// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: 'Roboto Condensed', sans-serif;
  --content-width: 1920px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #1E1E1E;
}
