/** Footer **/

.footer {
    padding: 75px 0 10px;
    position: relative;
    background-color: #23222C;
    color: #FFF;
    background-image:url(../img/slogan.svg), url(../img/eclipse.svg), url(../img/Exmlid.svg);
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: 80% 0, 100% 0, 0% 0%;
  }
  
  .footer__logo-text {
    font-size: 0.5em;
    margin-bottom: 96px;
  
  }
  
  
  .footer__main {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin-bottom: 50px;
  }
  
  
  .footer__list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 321px;
  }
  .footer__item {
    font-size: 1em;
    margin-bottom: 15px;
  
  }
  .footer__item-link {
  
  }
  
  .footer__contact-list {
  
  }
  
  .footer__contact-link {
    display: flex;
    align-items: center;
  }
  
  .footer__contact-item {
    margin-bottom: 20px;
  }
  
  .footer__contact-svg {
    margin-right: 15px;
  }
  
  .footer__contact-text {
  
  }
  
  .footer__contact-work {
  
  
  }
  
  
  .footer__social-list {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    padding: 25px 0;
    margin: 0 auto;
    height: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    margin-bottom: 35px;
  }
  .footer__social-item {
  
  }
  .footer__social-icon {
    fill: rgba(255, 255, 255, 0.75);
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .footer__lower {
    display: flex;
    flex-direction: column;
    background: rgba(22, 27, 34, 0.50);
    width: 100%;
    align-items: center;
    font-size: 0.8em;
    line-height: 20px;
    padding-top: 25px;
    padding-bottom: 45px;
  }
  .footer__lower-design {
  margin-bottom: 12px;
  }
  .footer__lower-adress {
    margin-bottom: 12px;
  }
  .footer__lower-inn {
    margin-bottom: 38px;
  }
  .footer__lower-block {
    display: flex;
    justify-content: space-between;
    width: 70%;
  }
  .footer__lower-text {
    width: 47%;
  }
  
  .footer__basement-list {
    display: flex;
    justify-content: space-between;
    background-color: rgba(10, 20, 47, 0.1);
    padding-top: 35px;
    padding-bottom: 85px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    margin-bottom: 25px;
  }

  .footer__basement-item {
    padding: 35px 0;
  }
  
  .footer__text {
    text-align: center;
  font-size: 0.7rem;
  padding: 30px 0;
  }
  
  